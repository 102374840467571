.wishListWrapper {
    min-height: calc(100vh - 448px);
  }
  .container {
    width: 90%;
    margin: auto;
    padding: 20px 15px 0px;
  }
.wizard{
    padding-top: 83px;
    padding-bottom: 83px;
}
.address_left {
    width: 60%;
    display: flex;
    gap: 30px;
}
.verticalDivider {
    height: 100%;
    width: 1px;
    background: #ededed;
}
.shoppingCartDivider{
    background-color: #e2e2e2;
    width: 100%;
    height: 1px;
    margin-top: 16px;
    margin-bottom: 16px;
}
.wizard .left::after, .address-section .address-left::after {
    content: "";
    height: 100%;
    width: 1px;
    background: #ededed;
    position: absolute;
    right: 0;
}
.wizard > div.wizardInner {
position: relative;
margin-bottom: 50px;
text-align: center;
}
.wizard .navTabs {
position: relative;
border-bottom-color: transparent;
display: flex;
justify-content: center;
padding: 0;
margin: 0;
gap: 120px;
}
.wizard .navTabs > li.active > a i {
    color: #eb2675;
}
.navTabs {
border-bottom: 1px solid #ddd;
}
.nav > li {
    position: relative;
    display: flex;
}


ul.nav.navTabs::before {
content: '';
height: 1px;
background: #3333;
position: absolute;
width: 100%;
margin: 0 auto;
left: 0px;
right: 0;
top: -4px;
z-index: 1;
}
ul.tabs.wc-tabs.nav.navTabs::before{
display: none;
}
.wizard .navTabs > li.active > a, .wizard .navTabs > li.active > a:hover, .wizard .navTabs > li.active > a:focus {
color: #555555;
cursor: default;
border: 0;
border-bottom-color: transparent;
}
.wizard li.active span.round-tab {
    background: #eb2675;
color: #fff;
border-color: #eb2675;
}
.wizard li.active span.round-tab i{
color: #5bc0de;
}
.wizard .navTabs > li.active > div i{
color: #eb2675;
}

.wizard .navTabs > li {
width: fit-content;
}
.wizard li:after {
content: " ";
position: absolute;
left: 46%;
opacity: 0;
margin: 0 auto;
bottom: 0px;
border: 5px solid transparent;
border-bottom-color: red;
transition: 0.1s ease-in-out;
}
.wizard .navTabs > li div {
border-radius: 100%;
padding: 0;
background-color: transparent;
position: relative;
top: 0;
width: fit-content;
border: 0;
}
.wizard .navTabs > li div i{
position: absolute;
top: -35px;
font-style: normal;
transform: translate(-50%, -50%);
font-size: 25px;
font-weight: 700;
text-transform: uppercase;
color: #333333;
cursor: pointer;
}
.wizard .navTabs > li.active > div p::before {
color: #eb2675;
border: 2px solid #eb2675;
content: "∙";
width: 16px;
height: 16px;
top: -13px;
}
.wizard .navTabs > li p {
font-style: normal;
font-size: 16px;
text-transform: uppercase;
color: #333333;
width: fit-content;
cursor: pointer;
padding-top: 10px;
}
.wizard .navTabs > li p::before {
content: "";
position: absolute;
background-color: #fff;
top: -10px;
border-radius: 50px;
color: #333333;
display: flex;
width: 12px;
height: 12px;
left: 0;
right: 0;
justify-content: center;
font-size: 16px;
line-height: normal;
border: 1px solid #333333;
z-index: 99;
align-items: center;
margin: 0 auto;
}
.wizard .navTabs > li.active > div p {
color: #eb2675;
font-weight: 600;
}
.wizard .navTabs > li div:hover {
background: transparent;
}
.wizard .tab_pane {
position: relative;
padding-top: 20px;
display: flex;
}
.wizard .navTabs::after{
display: none;
}




.wizard h3 {
    margin-top: 0;
}
.shopping_bag_content{
    width: 40%;
    padding-left: 30px;
}

/* .price_detil_section{
    padding-top: 40px;
} */



/***default-address****/
.right {
    display: flex;
    gap: 30px;
    flex-direction: column;
}
.shopping_bag_content .right .default_address_section {
    background: #F4EAEF;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}
.adress_and_change_address {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
}
.shopping_bag_content .right .changeAddress::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #ededed;
    position: absolute;
    bottom: -20px;
    left: 0;
}
.shopping_bag_content .right .default_address_section p {
    font-size: 16px;
}
.shopping_bag_content .right .default_address_section .address_with_pincode {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.shopping_bag_content .right .default_address_section .address_with_pincode p.deafult_address {
    background-color: #eb2675;
    padding: 5px 5px 4px 5px;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;
    line-height: normal;
}
.name_and_pincode{
    text-transform: capitalize;
}
.shopping_bag_content .right .default_address_section p.address {
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 400;
}

.addressFormUI label, .modal-dialog.editprofile label {
    font-size: 13px;
}
.pincode-and-locality, .addressFormUIcityContainer {
    display: flex;
    gap: 20px;
}
.pincode-and-locality .addressFormUI-pincodeBlock, .pincode-and-locality .localityoptions-base, .addressFormUIcityContainer .inputV2inputRow.addressFormUIhalfWidth, .addressFormUIcityContainer .inputV2inputRow.addressFormUIhalfWidth {
    width: 50%;
}

.prev-step,
.next_step{
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 30px;
}
.next_step, button.edit-profile, button.filter-btn, button.vieworderdetails, button.btn1, .write-review-content input[type="submit"], button.button.add-to-cart, button.button.alt{
    background-color: #eb2675 ;
    border: 1px solid #eb2675 ;
    color: #fff ;
    border-radius: 0 !important;
    padding: 15px 20px;
    font-size: 14px;
    line-height: normal !important;
    font-weight: 600 !important;
    width: max-content;
    text-transform: uppercase;
}
button.btn1.small {
    font-size: 12px;
    padding: 6px 8px;
    background: transparent ;
    color: #eb2675 ;
}
button.default_btn.next_step:hover, button.edit-profile:hover, button.filter-btn:hover,button.vieworderdetails:hover, button.btn1:hover, input#myFile::file-selector-button:hover, .write-review-content input[type="submit"]:hover, button.button.add-to-cart:hover, button.button.alt:hover{
    background-color: transparent ;
    color: #eb2675 ;
}
button.button-wish, button.btn2{
    background-color: transparent !important;
    border: 1px solid #eb2675 !important;
    color: #eb2675 !important;
    border-radius: 0 !important;
    padding: 15px 20px;
    font-size: 14px;
    line-height: normal !important;
    font-weight: 600 !important;
    width: max-content;
    font-weight: 600 !important;
}
button.button-wish:hover, button.btn2:hover{
    background-color: #eb2675 !important;
    border: 1px solid #eb2675 !important;
    color: #fff !important;
    font-weight: 600 !important;
}
button.default_btn.next_step:focus, button.edit-profile:focus, button.filter-btn:focus, button.vieworderdetails:focus, button.btn1:focus, input#myFile::file-selector-button:focus, .write-review-content input[type="submit"]:focus, button.button.add-to-cart:focus, button.button.alt:focus, button.button-wish:focus, button.btn2:focus{
    outline: 0 !important;
    background: #eb2675 !important;
    color: #fff !important;
}
.skip-btn{
	background-color: #cec12d;
}
.step-head{
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}
.term-check{
	font-size: 14px;
	font-weight: 400;
}
.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}

/* .changeAddress::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #ededed;
    position: absolute;
    bottom: -20px;
    left: 0;
} */
.addMoreItems {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}
.addMoreItems {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    gap: 10px;
    align-items: center;
    font-size: 18px;
}
.addMoreItems i.pe-7s-cart {
    font-size: 26px;
}
.changeAddress {
    padding-top: 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}
.addMoreItems {
    padding-top: 30px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 600;
}
/* address left section */
.shopping_bag_content .right .price_detil_section, .address-section .address-right .price_detil_section, .payment-content .right .price_detil_section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.shopping_bag_content .right .price_detil_section p.heading, .address-section .address-right .price_detil_section p.heading, .payment-content .right .price_detil_section p.heading {
    font-size: 16px;
}
.shopping_bag_content .right .price_detil_section .price_info, .address-section .address-right .price_detil_section .price_info, .payment-content .right .price_detil_section .price_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.shopping_bag_content .right .price_detil_section .price_info .two_values, .address-section .address-right .price_detil_section .price_info .two_values, .payment-content .right .price_detil_section .price_info .two_values {
    display: flex;
    justify-content: space-between;
}
.shopping_bag_content .right .price_detil_section .price_info .two_values p.price.green, .address-section .address-right .price_detil_section .price_info .two_values p.price.green,  .payment-content .right .price_detil_section .price_info .two_values p.price.green{
    background-color: transparent;
    color: #03a685;
}
.shopping_bag_content .right .price_detil_section .price_info p, .shopping_bag_content .right .price_detil_section .total_amount p, .address-section .address-right .price_detil_section .price_info p, .address-section .address-right .price_detil_section .total_amount p, .payment-content .right .price_detil_section .price_info p{
    font-size: 15px;
}
.shopping_bag_content .right .price_detil_section .total_amount, .address-section .address-right .price_detil_section .total_amount, .payment-content .right .price_detil_section .total_amount {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.shopping_bag_content .right .price_detil_section .total_amount::before, .address-section .address-right .price_detil_section .total_amount::before, .payment-content .right .price_detil_section .total_amount::before{
    content: "";
    background-color: #ededed;
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
}
.shopping_bag_content .right .price_detil_section .total_amount p, .address-section .address-right .price_detil_section .total_amount p, .payment-content .right .price_detil_section .total_amount p {
    padding-top: 10px;
    font-weight: 600;
    font-size: 16px;
}

.horizontalShake:hover{
    animation: horizontal-shaking 0.35s infinite;
    cursor: no-drop;
    background-color: #495057 !important;
    border: none !important;
}
.horizontalShake{
    background-color: #495057 !important;
    border: none !important;
    color: #fff !important;
}
@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }

.errorMessage {
   color: #eb2675;
}

@media (max-width: 1190px) {
    .couponBox {
        flex-direction: column;
    }
    .couponInputBoxSection{
        width: 100%;
    }
}
@media (max-width: 991px) {
.CartSteps_shopping_bag_content {
    width: 30%;
    padding-left: 30px;
}
}

@media (max-width: 767px) {
    .shopping_bag_content{
        width: 100%;
        padding-left: 0px;
    }
    .wizard .navTabs{
        gap: 50px;
    }
    .address_left {
        width: 100%;
        gap: 0px;
    }
    .wizard .tab_pane {
        position: relative;
        padding-top: 20px;
        gap: 20px;
        display: flex;
        flex-direction: column;
        }
        .couponBox {
            display: flex;
            gap: 12px;
            padding-bottom: 10px;
            flex-direction: column;
        }
        .couponInputBoxSection {
            background-color: #f4f5f6;
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 9px;
        }
}
@media (max-width: 550px) {
    .shopping_bag_content .left .carts_sction .cart {
        flex-direction: column;
        gap: 25px;
        position: relative;
    }
    
}


.couponcodesection .copucodetextsection p.save-money-text {
    padding: 12px 0;
    font-size: 16px;
}
.couponcodesection .copucodetextsection p {
    font-size: 14px;
}
.couponcodesection .copucodetextsection p.copuncodetextwithborder {
    padding: 9px;
    border: 1px solid #eb2675;
    width: fit-content;
    font-size: 16px;
    color: #eb2675;
    font-weight: 600;
}
.couponcodesection .copucodetextsection {
    display: flex;
    flex-direction: column;
}
.couponCodeList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.couponCodeList .couponcodesection {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background: #F5F5F5;
    padding: 20px;
    border-bottom: 1px solid #EDEDED;
}
.couponInputBoxSection input.couponInputBox {
    background: transparent;
    border: transparent;
}
.couponInputBoxSection input.couponInputBox:focus-visible{
    border: 0;
    outline: 0;
}
.couponInputBoxSection i.pe-7s-ticket {
    font-size: 20px;
}
.couponBox {
    display: flex;
    gap: 12px;
    padding-bottom: 10px;
    width: 100%;
}
.couponInputBoxSection {
    background-color: #f4f5f6;
    padding: 0px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 9px;
}
.couponInputBoxSection input.couponInputBox {
    background: transparent;
    border: transparent;
    width: 100%;
    padding: 10px 0px;
}
button.addNewAddress {
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #eb2675;
    padding: 8px 16px 7px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    color: #eb2675;
    text-transform: uppercase;
}
button.addNewAddress:hover, button.addNewAddress:active, button.addNewAddress:focus {
    background: transparent;
    border: 1px solid #eb2675;
    color: #eb2675;
    outline: 0;
}
.couponInputBoxSection input.couponInputBox:focus-visible{
    border: 0;
    outline: 0;
}
.maximumSavingContent {
    width: fit-content;
    display: flex;
    gap: 12px;
}
.couponInputBoxSection i {
    font-size: 20px;
}
.couponCodeApplySection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #ededed;
    border-radius: 6px;
    padding: 20px 20px;
}
.couponCodeApplySection p {
    font-size: 16px;
}