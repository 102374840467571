.ImageAndDetails {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #fff;
}
.ImageAndDetails img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.productTitle {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.productSize {
  font-size: 15px;
  color: #333;
}
.reviewTextarea textarea {
  min-height: 90px;
  width: 100%;
  border-color: #9d9a9b;
  color: #1a1a1a;
  padding: 10px;
}
.reviewTextarea textarea:focus {
    border-color: #9d9a9b;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.contentWrapper p {
  font-size: 16px;
  font-weight: 600;
}
.reviewText {
  font-size: 14px;
font-weight: 400;

}
.reviewTerm {
  color: #eb2675;
  font-weight: 600;
  font-size: 14px;
}
input[type="file"]::file-selector-button {
  margin-right: 20px;
  margin-top: 10px;
  border: none;
  background: #eb2675;
  padding: 10px 20px;
  border-radius: 0px;
  color: #fff;
  cursor: pointer;
}
.addressFormUIDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
