.container {
  width: 1170px;
  margin: auto;
  padding: 20px 15px 100px;
}
.userName {
  display: flex;
  padding: 10px 0 25px 0;
  gap: 10px;
}
.heading {
  font-size: 16px;
}
.name {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.tabContainer {
  display: flex;
}
.tab {
  border: 1px solid #ccc;
  width: 22%;
  border-left: none;
  border-bottom: none;
  border-right: 1px solid #ccc;
  padding-right: 20px;
}
.tablinks {
  display: block;
  width: 100%;
  outline: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  border-radius: 0;
  padding: 20px;
  border: none;
  background: transparent;
  line-height: normal;
  text-transform: uppercase;
  border-bottom: 1px solid #dbdbdb;
}
.tablinks:hover {
  color: #eb2675 !important;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .container {
    width: 970px;
  }
  .tablinks {
    padding: 20px 0;
  }
}

@media (max-width: 992px) {
  .container {
    width: 750px;
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 20px 19px 100px;
  }
}
