.selectSorting {
  background-color: #fff;
  border: 1px solid #9d9a9b;
  color: #7a8081;
  padding: 0.67em 0.5em;
  font-size: 14px;
  display: block;
  justify-content: space-between;
  margin-top: auto;
}

.CategogiesHeader {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.CategogiesHeader p {
  margin-bottom: 0.4rem;
}
.CategogiesHeader h6 {
  margin-bottom: 1.5rem;
}
.CategogiesHeader h6 span {
  font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
  .CategogiesHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

/* ============================filter panel css===================== */
.filterTitle {
  font-size: 20px;
  position: relative;
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #333;
}

.filterTitle::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
  background-color: #eb2675;
  left: 0;
  bottom: -2px;
}

/* ---------check box style start---------------- */
/* The container */
.categoryLabel {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
}
.clearText{
  color: #eb2675;
  text-align: left;
  padding-top: 10px;
  font-size: 14px;
}
/* Hide the browser's default checkbox */
.categoryLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid #eb2675;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
/* .categoryLabel:hover input ~ .checkmark {
  background-color: #ccc;
} */
.categoryLabel:hover {
  color: #eb2675;
}

/* When the checkbox is checked, add a blue background */
.categoryLabel input:checked ~ .checkmark {
  background-color: #fff;
  border: 0.02rem solid #eb2675;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.categoryLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.categoryLabel .checkmark:after {
  left: 5px;
  top: -5px;
  width: 8px;
  height: 14px;
  border: solid #eb2675;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 9;
}
/* -----------checkbox style end--------- */

.filterForm svg {
  font-size: 1.2rem;
  color: #eb2675;
}

.filterForm label {
  font-weight: 500;
  margin-left: 0.5rem;
  margin-bottom: 0.3rem;
}

.devider {
  height: 25px;
  width: 100%;
}
.price_range_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.input_price_filter{
  width: 80%;
} 

.input_price_filter .clear_all {
  cursor: pointer;
  color: #eb2675;
  font-size: 12px;
}

.facet_min_price_filter {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
}

.input_price_filter input,
.input_price_luxe_filter input {
  width: 46px;
  font-size: 14px;
  height: 32px;
  padding: 5px;
  border: 1px solid #ddd;
}

.price_container .input_price-filter button {
  width: auto;
  line-height: 0 !important;
  background: transparent !important;
  border: 1px solid #eb2675;
  color: #eb2675;
  font-size: 14px;
  padding: 6px;
}
.rangeButton {
  border: 1px solid #eb2675;
}

/* ====================products ========================== */

.viewStyle button {
  background-color: #fff;
  border: 1px solid #000;
  padding: 5px 10px;
  margin: 5px;
  font-size: 1.2rem;
  transition: 0.5s;
}
.viewStyle button:hover {
  background-color: #eb2675;
  border: 1px solid #eb2675;
  color: #fff;
}

.product_list_view {
  display: flex;
  flex-direction: row;
}

.productLink {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.productCarousel {
  position: absolute;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  top: 0;
  height: 100%;
}
.product:hover figure .product-wrap .productCarousel {
  transition: linear 0.3s;
  visibility: visible;
  opacity: 1;
}

.productCarouselItem .slideImage {
  background-size: cover;
  background-repeat: no-repeat;
}

.productCarouselItem {
  position: absolute;
  width: 100%;
  border: none;
  top: 0;
  left: 100%;
  height: 100%;
}

.productCarouselItem.active {
  left: 0;
  transition: all 0.3s ease-out;
}

.productCarouselItem div {
  height: 100%;
}

.productContainer {
  position: relative;
  transition: 0.5s ease;
}
.product:hover .productContainer {
  box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.07);
}

.addTowishList {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
  padding: 15px 20px 10px 20px;
  transform: scaleY(0);
  transform-origin: bottom; /* Added to set the transform origin to the bottom */
  transition: transform 0.4s ease; /* Changed transition property to target transform */
}

.product:hover .addTowishList {
  transform: scaleY(1);
}

.addTowishList p {
  margin: 1rem 0 0 0;
  color: #333;
}

.addTowishList button {
  border: 1px solid #eb2675;
  background-color: #fff;
  text-align: center;
  width: 100%;
  display: block;
  margin: auto;
  padding: 5px 12px;
  font-size: 0.9rem;
  font-weight: bold;
}


.product h5 {
  font-size: 1rem;
  margin-top: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 16px;
}
.stars {
  color: #eb2675;
}
.price a:first-child {
  text-decoration: line-through;
  font-size: 18px;
}
.price a:nth-child(2) {
  text-decoration: none;
  color: #eb2675 !important;
  font-size: 18px;
  margin-left: 10px;
}

.product img {
  vertical-align: middle;
  object-fit: cover;
  width: 100% !important;
}

.breadcrumbsAndFilter {
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0px 15px 30px !important;
  box-sizing: border-box;
}
.breadcrumbsAndFilter hr {
  width: 100%;
  margin: 0;
}

.breadcrumbsAndTittle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rowBase .titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rowBase .titleContainer h5.title-title {
  margin: 0;
}
.breadcrumbBg{
  background: #F5EBEF;
  margin-bottom: 20px;
}
.rowBase .breadcrumbsBase ul.breadcrumbsList {
  list-style: none;
  margin: 0;
  display: flex;
  gap: 8px;
}
.woocommerceBreadcrumbWrapper{
  display: flex;
  gap: 8px;
  background-color: transparent !important;
  align-items: center;
}
.woocommerceBreadcrumb {
  padding: 10px 0;
  display: flex;
  gap: 8px;
  background-color: transparent !important;
  align-items: center;
}

.breadcrumbsText {
  display: inline-block;
  color: #eb2675;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
}

.woocommerceBreadcrumb .breadcrumbsText::after {
  content: ">";
  display: inline-block;
  margin: 0 3px 0 6px;
  color: #5a5254;
}
.woocommerceBreadcrumb :last-child::after {
  content: '';
  display: none;
  margin: 0 3px 0 6px;
  color: #5a5254;
}

.woocommerceBreadcrumb a:hover {
  color: #5a5254;
}

.storefrontSorting {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.storefrontSorting .woocommerce-ordering,
.storefrontSorting .short-by,
.storefrontSorting .woocommerce-result-count {
  display: inline-block;
  vertical-align: middle;
  float: right;
  padding-left: 6px;
}

.storefrontSorting .short-by .btn.btn-default {
  padding: 0.71em 1em;
}

.storefrontSorting .short-by .btn.btn-default i {
  margin: 0;
  right: 0;
}

.storefrontSorting .short-by .btn.btn-default:hover,
.storefrontSorting .short-by .btn.btn-default.active {
  box-shadow: none;
  transform: translateY(0);
  color: #fff;
}

.storefrontSorting .short-by .btn.btn-default:hover i {
  padding: 0;
}

.storefrontSorting .woocommerceResultCount {
  color: #a09a9c;
}

.imageEffect {
  display: block;
  overflow: hidden;
}

.imageEffect img {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  width: -webkit-fill-available;
}

.imageEffect:hover img {
  transform: scale(1.02);
}

.buttonPanel {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  border-top: 1px solid #ddd;
  padding-top: 50px !important;
}
.buttonPanel li a.prev.buttonPanel {
  margin-right: 40px;
  width: -webkit-fill-available;
}
.buttonPanel li a.next.buttonPanel {
  margin-left: 40px;
  width: -webkit-fill-available;
}
.buttonPanel li a {
  padding: 10px 10px !important;
}
.buttonPanel li a.prev.buttonPanel:hover,
.buttonPanel li a.next.buttonPanel:hover {
  background: transparent !important;
  color: #eb2674 !important;
}

.pagination .buttonPanel li,
.woocommerce-pagination .buttonPanel li {
  list-style: none;
  display: inline-block;
}

.pagination .buttonPanel li .buttonPanel,
.woocommerce-pagination .buttonPanel li .buttonPanel,
.pagination li a {
  width: 42px;
  text-align: center;
  line-height: 1;
  padding: 0.72em 0;
  border: 1px solid #5a5254;
  display: block;
  color: #5a5254;
}

.pagination .buttonPanel li a.buttonPanel:hover,
.woocommerce-pagination .buttonPanel li a.buttonPanel:hover,
.pagination .buttonPanel li .buttonPanel.current,
.woocommerce-pagination .buttonPanel li .buttonPanel.current,
.pagination li a:hover {
  color: #fff;
}
.rowWrapper{
  padding: 0px;
}
.productContainer{
   height: 320px;
}
.productlistCategoriesContainer{
  padding: 0px 15px;
}
.productlistContainer{
  padding: 0px 15px 0px 35px;
}
.productListWrapper{
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .productListWrapper {
    flex-direction: column-reverse;
  }
  .productlistContainer,.productlistCategoriesContainer{
    width: 100%;
  }
  .productlistContainer{
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1440px) {
  .rangeButton {
    width: 100%;
    margin-top: 10px;
  }

  .input_price_filter input,
  .input_price_luxe_filter input {
    width: 100%;
  }

  .price_range_title {
    flex-direction: column;
    align-items: flex-start;
  }

  .facet_min_price_filter{
    justify-content: center;
  }
}
@media (max-width: 575px){
  .productLink,.productContainer{
    height: 500px;
  }
}
@media (max-width: 400px){
  .productLink,.productContainer{
    height: 400px;
  }
}