.tabcontent {
  padding: 20px;
  border: 1px solid #ccc;
  width: 78%;
  border-left: none;
  border-bottom: none;
  border-right: none;
}
.allOrdersHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.searchAndFilterWrapper {
  display: flex;
  gap: 20px;
}
.searchBoxForm {
  background-color: #f4f5f6;
  padding: 0px 10px;
  min-width: 200px;
  width: 200px;
  position: relative;
  right: 0;
}
.searchBoxInput {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: #f4f5f6;
  padding: 10px 0px 10px 25px;
}
.searchBoxInput::placeholder {
  color: #5a5254;
}
.searchBoxIcon {
  left: 6px;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  position: absolute;
}
.searchIcon {
  width: 22px;
  height: 22px;
}
.filterBtn {
  display: flex;
  align-items: center;
  background-color: #eb2675 !important;
  border: 1px solid #eb2675 !important;
  color: #fff !important;
  border-radius: 0;
  padding: 15px 20px !important;
  font-size: 14px !important;
  line-height: normal;
  font-weight: 600;
  width: max-content;
  text-transform: uppercase !important;
  text-align: center !important;
  justify-content: center;
}
.filterBtn:hover {
  /* background-color: transparent; */
  /* color: #eb2675; */
  background-color: #5a5254;
  color: #fff;
}
.filterBtn i {
  padding-right: 10px;
  font-size: 20px;
}
.orderListWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #f5f5f5;
  padding: 20px;
  margin: 0px 0px 10px 0px;
  border-bottom: 1px solid #ededed;
}
.list:hover {
  border-bottom: 1px solid #eb2675;
}
.orderDetailsWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 12px;
}
.orderID {
  font-size: 14px;
  color: #333;
}
.deliveryStauts {
  font-size: 14px;
  font-weight: 600;
  color: green;
}
.deliveryStauts span {
  font-weight: 400;
  color: #000;
}
.imagedetailsWrapper {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
}
.imagedetailsWrapper img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.productTitle {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.productSize,
.returnWinodw {
  font-size: 15px;
  color: #333;
  font-weight: 400;
}
.cardBtnWrapper {
  display: flex;
  gap: 20px;
}
.viewOrderDetails {
  padding: 10px 15px !important;
}
.productReview {
  color: #eb2675;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
}
@media (max-width: 767px) {
  .tabcontent {
    width: 100%;
    padding: 0;
  }
  .allOrdersHeaderWrapper {
    padding-top: 20px;
  }
}
@media (max-width: 550px) {
  .tabcontent {
    width: 100%;
    padding: 0;
  }
  .allOrdersHeaderWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .filterBtn,.btn2 {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .productSize,
  .returnWinodw {
    font-size: 13px;
  }
  .viewOrderDetails {
    padding: 10px !important;
  }
  .productReview {
    width: 100%;
  }
}

.orderList .list .itemImageAndDetails {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
}
.orderList .list .itemImageAndDetails img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.orderDetails .itemImageAndDetails img {
  width: 205px !important;
  height: 205px !important;
}
.orderDetailsListContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}
.orderDetailsListContent .address-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.orderDetailsListContent .addressContent .nameAndPhonenumber {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.nameAndPhonenumber b,.addressText {
  font-size: 14px;
}
.addressText {
  font-weight: 400;
}
.addressContent{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.orderDetailsListContent
  .addressContent
  .nameAndPhonenumber
  p.middlePipe::before {
  content: "";
  position: relative;
  width: 1px;
  border-left: 1px solid #7a7a7a;
}
.orderDetailsListContent .details {
  background: #fff;
  padding: 10px 15px;
}
.ratingAndReviwOrderDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.ratingAndReviwOrderDetails .rateProduct p.heading,
.totalPriceSection p.heading,
.orderDetailsListContent .details p.heading {
  font-size: 16px;
  font-weight: 600;
}
.ratingAndReviwOrderDetails .rateProduct .ratingStars {
  color: #eb2675;
  font-size: 18px;
  cursor: pointer;
}
.ratingAndReviwOrderDetails .rateProduct {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #fff;
  width: -webkit-fill-available;
  padding: 10px 15px;
}
.writeReview {
  background: #fff;
  width: -webkit-fill-available;
}
.writeReview p {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 15px;
  color: #eb2675;
  font-size: 16px;
  cursor: pointer;
}
.orderDetails .orderIdAndDeliverStatus {
  padding-bottom: 20px !important;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #fff;
}
.trackingSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.trackingSection .deliveryDates,
.trackingSection .deliveryTrack {
  display: flex;
  justify-content: space-between;
}
.trackingSection .deliveryDates p,
.trackingSection .deliveryTrack p {
  text-align: center;
  width: -webkit-fill-available;
  position: relative;
  margin: 0 auto;
}
.trackingSection .deliveryTrack p {
  color: green;
  font-weight: 600;
}
.trackingSection .deliveryLine {
  width: -webkit-fill-available;
  height: 2px;
  background: green;
  border-radius: 5px;
  margin: 0 100px;
}
.trackingSection .deliveryTrack p::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  background: green;
  margin: 0 auto;
  text-align: center;
  top: -16px;
  left: 95px;
}
.totalPriceSection {
  align-items: center;
  background: #fff;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: -webkit-fill-available;
  border-top: 1px solid #ededed;
}
.totalPriceSection p.heading span.savedPrice {
  font-size: 14px;
  font-weight: 400;
}
.backButton {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.totalPriceSection p.heading span.savedPrice span.greenColor {
  color: green;
  font-weight: 600;
}
.totalPriceSection p.priceText {
  font-size: 18px;
  font-weight: 600;
  color: #eb2675;
}
.getInvoiceBtn button.btn2 {
  width: -webkit-fill-available;
}
button.btn2 {
  background-color: transparent !important;
  border: 1px solid #eb2675 !important;
  color: #eb2675 !important;
  border-radius: 0 !important;
  padding: 15px 20px;
  font-size: 14px;
  line-height: normal !important;
  font-weight: 600 !important;
  width: max-content;
  font-weight: 600 !important;
  text-align: center;
  text-transform: uppercase;
}
button.btn2:hover {
  background-color: #eb2675 !important;
  border: 1px solid #eb2675 !important;
  color: #fff !important;
  font-weight: 600 !important;
}
button.btn2:focus {
  outline: 0 !important;
  background: #eb2675 !important;
  color: #fff !important;
}
.trackOrderText {
  cursor: pointer;
}
.trackOrderText:hover {
  text-decoration: underline;
  color: #eb2675;
}
.orderDetailsAndRatingReview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.productreviewOrVieworderdetails {
  display: flex;
  gap: 20px;
}

.ratingAndReview .review {
  color: #7a7a7a;
}
.ratingAndReview .rating a {
  display: flex;
  align-items: center;
  gap: 5px;
  background: green;
  color: #fff;
  padding: 8px 10px;
}
.ratingAndReview {
  font-size: 12px;
}
.ratingAndReview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.otherItemsInThisOrder {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: #fff;
  gap: 20px;
}
.otherItemsInThisOrder .headingAndOrderId {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.otherItemsInThisOrder .headingAndOrderId p.heading {
  font-size: 18px;
  font-weight: 600;
}
.otherItemsInThisOrder .headingAndOrderId p.orderId {
  font-size: 14px;
  color: #7a7a7a;
}

.otherItemsInThisOrder .otherOrderList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.otherItemsInThisOrder .otherOrderList .otherOrdersDetailsAndArrowIcon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.otherItemsInThisOrder
  .otherOrderList
  .otherOrdersDetailsAndArrowIcon
  .itemImageAndDetails {
  padding-bottom: 0;
  border: 0;
}

.otherItemsInThisOrder .otherOrderList .otherOrdersDetailsAndArrowIcon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  background: #f5f5f5;
  padding: 10px 15px;
  border-bottom: 1px solid #ededed;
  align-items: center;
  cursor: pointer;
  transition: linear 0.2s;
}
.otherItemsInThisOrder .otherOrderList .otherOrdersDetailsAndArrowIcon:hover {
  background: #ededed;
  transition: linear 0.2s;
  border-bottom: 1px solid #eb2675;
}
.otherItemsInThisOrder
  .otherOrderList
  .otherOrdersDetailsAndArrowIcon
  .itemImageAndDetails
  img.otherOrderProductImage {
  width: 80px !important;
  height: 80px !important;
}
.otherItemsInThisOrder
  .otherOrderList
  .otherOrdersDetailsAndArrowIcon
  .itemImageAndDetails
  .details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.otherItemsInThisOrder
  .otherOrderList
  .otherOrdersDetailsAndArrowIcon
  .itemImageAndDetails {
  align-items: center;
}
.otherItemsInThisOrder
  .otherOrderList
  .otherOrdersDetailsAndArrowIcon
  .arrowIcon
  .pe7sAngleRight {
  font-size: 30px;
  font-weight: 500;
  background: #fff;
  border-radius: 50px;
}
.orderList{
  color: #333;
}

@media only screen and (max-width: 767px) {
  .orderDetails .itemImageAndDetails {
    flex-direction: column;
    align-items: center;
  }
  .orderDetails .orderList .list .itemImageAndDetails .details p.productTitle, .orderDetails .ratingAndReviwOrderDetails .rateProduct p.heading, .orderDetails .totalPriceSection p.heading, .orderDetails .orderDetailsListContent .details p.heading {
    font-size: 14px;
  }
  .orderDetails .orderDetailsListContent .addressContent .nameAndPhonenumber, .orderDetails .orderDetailsListContent .addressContent p.address-text {
    font-size: 12px;
  }
  .orderDetails .trackingSection {
    flex-direction: row;
    gap: 30px;
    justify-content: flex-start !important;
  }
  .orderDetails .trackingSection .deliveryDates, .orderDetails .trackingSection .deliveryTrack {
    flex-direction: column;
    gap: 30px;
  }
  .orderDetails .trackingSection .deliveryLine {
    width: 2px;
    height: inherit;
    margin: 0 0 10px 0;
  }
  .orderDetails .trackingSection .deliveryDates p, .orderDetails .trackingSection .deliveryTrack p {
    margin: 0;
    text-align: left;
    font-size: 12px;
  }
  .orderDetails .trackingSection .deliveryTrack p::before {
    left: -36px;
    top: 0px;
  }
}