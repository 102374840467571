.wishListWrapper {
  min-height: 70vh;
}
.container {
  width: 90%;
  margin: auto;
  padding: 20px 15px 0px;
}
.wishlistContentSection {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.headingWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.heading {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  color: #333;
}
.count {
  font-size: 16px;
  line-height: normal;
  color: #333;
}
.products {
  display: flex;
  flex-wrap: wrap;
}
.wishlistContentSection ul {
  padding-left: 0 !important;
}
.product {
  width: 20%;
  padding-bottom: 50px;
}
.productInnerSection {
  padding: 0;
  border: 1px solid #ededed;
  margin: 0 50px 0 0;
  height: auto;
  position: relative;
  cursor: pointer;
}
.productInnerSection figure {
  display: table;
  width: 100%;
  height: 100%;
}
.closeIcon {
  font-size: 30px;
  color: white;
  font-weight: 500;
  position: absolute;
  z-index: 99;
  right: 2%;
  top: 1%;
  border-radius: 20px;
  cursor: pointer;
  fill: #eb2675;
}
.content {
  padding: 0 25px 20px;
}
.content h6 {
  height: 24px;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}
.priceStarWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  padding-bottom: 20px;
}
.price {
  font-size: 18px;
  display: flex;
  gap: 6px;
}
.price del {
  color: #5a5254;
  font-weight: 400;
}
.price ins {
  color: #eb2675;
  font-weight: 600;
}
.addToCart {
  color: #fff;
  background: #eb2675;
  border: 1px solid #eb2675;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  padding: 10px 30px;
  width: max-content;
  text-transform: uppercase;
  font-weight: bold;
  height: 38px;
}
.addToCart:hover {
  background: #fff;
  color: #eb2675;
  transition: linear 0.1s;
}
.productWrap {
  width: 100%;
  height: 320px;
  position: relative;
}
.productWrap a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.productInnerSection a {
  width: 100%;
  height: 100%;
}
.productWrap a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  opacity: 0;
  left: 0;
  top: 0;
}
.productWrap img {
  vertical-align: middle;
  object-fit: cover;
  width: 100% !important;
  height: 320px;
}
.product:hover .content h6 {
    color: #eb2675 ;
}
.product:hover .productInnerSection{
    box-shadow: 0 2px 16px 4px rgba(40,44,63,.07);
}
.wishListNoDataWapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
  min-height: inherit;
  margin: auto;
}
.wishListNoDataTitle {
  font-size: 24px;
  color: #333;
  text-transform: uppercase;
  font-weight: 600;
}
.wishListNoDataSubTitle{
 font-size: 16px;
 max-width: 380px;
 text-align: center;
}
.wishListNoDataBtn {
  padding: 0.95em 2.4em;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  border-radius: 0;
  background-color: #eb2675;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #eb2675;
  text-transform: uppercase;
  margin-top: 25px;
}
.wishListNoDataBtn:hover {
  color: #eb2675;
  background-color: #fff;
}
.addCart {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
}
.starRating{
  white-space: nowrap;
}
@media (max-width: 1650px) {
  .product {
    width: 25%;
  }
}
@media (max-width: 1300px) {
  .content {
    padding: 0 15px 20px;
  }
}
@media (max-width: 1279px) {
  .content {
    padding: 0 12px 20px;
  }
}
@media (max-width: 1199px) {
  .priceStarWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-top: 8px;
  }
}
@media (max-width: 1199px) {
  .productInnerSection {
    margin: 0 15px 0 0;
  }
  .product {
    width: 33%;
    margin: 0;
  }
  .addToCart,.wishListNoDataBtn {
    padding: 10px 15px;
  }
}
@media (max-width: 767px) {
  .wishlistContentSection {
    padding-top: 0px;
  }
  .product {
    width: 50%;
    padding-bottom: 30px;
  }
  .addToCart,.wishListNoDataBtn {
    font-size: 12px;
  }
  .price {
    font-size: 18px;
  }
  .heading {
    font-size: 18px;
  }
  .productWrap {
    margin-bottom: 10px;
  }
}
@media (max-width: 550px) {
  .product {
    width: 100%;
  }
  .productInnerSection {
    margin: 0;
  }
}
