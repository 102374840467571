.recommendedProduct {
  padding-bottom: 100px;
}
.recommendedProductTitle {
  margin: 0;
  display: inline-block;
  background: #fff;
  position: relative;
  top: 16px;
  padding: 0 15px;
  font-size: 24px;
  font-weight: 400;
  color: #333;
}
.cover {
  border-bottom: 1px solid #bebabb;
  margin-bottom: 65px;
  text-align: center;
}
.productSlider {
  margin: 0px;
  padding: 0;
  max-width: 1170px;
  align-items: center;
}
.productWrapper {
  padding-bottom: 20px;
  display: block !important;
  margin: auto;
  margin-bottom: 20px;
  width: max-content !important;
}
.productWrapper figure {
  position: relative;
  margin: 0 auto 20px;
  overflow: hidden;
  display: table;
  width: 100%;
}
.productWrapper:hover {
  box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.07);
  transition: linear 0.2s;
}
.productWrap {
  width: 270px;
  height: 360px;
}
.productWrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.product {
  padding-left: 10px;
  max-width: 270px;
}
.productName {
  font-size: 16px;
  font-weight: 400;
  color: #5a5254;
  padding-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;

}
.productWrapper:hover .productName {
  color: #eb2675;
}
.price {
  font-size: 18px;
  display: flex;
  gap: 6px;
}
.price del {
  color: #5a5254;
}
.price ins {
  color: #eb2675;
  font-weight: 600;
}
.productDetailsWrap {
  display: flex;
  gap: 16px;
  align-items: center;
}
@media (max-width: 1199px) {
  .productSlider {
    max-width: 990px;
  }
}
@media (max-width: 990px) {
  .productSlider {
    max-width: 790px;
  }
  .productWrap {
    width: 220px;
    height: 300px;
  }
  .product {
    max-width: 220px;
  }
}
@media (max-width: 767px) {
  .productSlider {
    width: 600px !important;
  }
  .productDetailsWrap {
    gap: 12px;
  }
  .productWrap {
    width: 200px;
    height: 290px;
  }
}

@media (max-width: 630px) {
  .productSlider {
    width: 500px !important;
  }
}
@media (max-width: 530px) {
  .productSlider {
    width: 400px !important;
  }
  .productWrap {
    width: 270px;
    height: 360px;
  }
  .product {
    max-width: 270px;
  }
}
@media (max-width: 430px) {
  .productSlider {
    width: 370px !important;
  }
}