.Tabs {
  border-radius: 0;
    color: #333 !important;
    font-weight: 500;
    padding: 15px 20px;
    display: block;
    border: 0;
    background-color: #f5f5f5;
    margin: 0;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    transition: 0.5s;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.Tabs-Active{
  background-color: #eb2675;
  color: #fff !important;

}

.Tabs:hover,
.Tabs:focus {
  background-color: #eb2675;
  color: #fff !important;
}

.Tabs:hover,
.Tabs:active,
.Tabs:focus {
  color: #fff;
  outline: 0px;
}
.productDescriptionTabsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.productDescriptionArrow{
  color: #fff;
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  align-content: center;
  font-size: 20px;
}