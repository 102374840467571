.footerContainer{
    padding: 100px 15px;
    max-width: 1170px;
    color: #333;
}
.footerRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.dataTitle{
    font-size: 18px;
    color: #333;
    margin-bottom: 20px !important;
    font-weight: 400;
}
.dataUl{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 0px;
    padding-left: 0px;
}
.listTitle {
    font-size: 14px;
    padding: 0px;
}
.listTitle a {
    color: #333;
    font-weight: 400;
}
.listTitle:hover a {
    padding-left: 3px;
    transition: all .3s ease-in-out;
}
.listTitle:hover{
    color: #eb2675;
}
.offertext{
    padding-top: 8px;
    padding-bottom: 20px;
    font-size: 14px !important;
}
.emailWrapper{
    display: flex;
    width: 100%;
    box-sizing: border-box;
}
.emailInput{
    padding: "8px 6px";
    border: 1px solid #fff;
    border-radius: 0;
    width: calc(100% - 40px);
}
.emailBtn{
    border: 1px solid #fff;
    border-radius: 0;
    padding: 8px;
    background-color: #eb2675;
    color: #fff;
    width: 40px;
    box-sizing: border-box;
}
.bottomText{
    font-size: 11px;
    color: #9f9c9d;
}
.footer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
    padding: 30px 15px;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1170px;
    margin: auto;
}

.footer button{
    width: 36px;
    height: 30px;
    /* font-size: 1rem; */
    color: #979494;
    background-color: unset;
    border: 1px solid #979494;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyrightText{
    font-size: 12px;
    width: 50%;
    padding: 5px 15px;
}
.iconWrapper{
    display: flex;
    gap: 5px;
    width: 50%;
    justify-content: flex-end;
    padding: 0px 15px;
}
.footerDataWrap {
    width: fit-content;
}
@media (max-width: 1199px) {
    .footerContainer{
        padding: 50px 15px;
    }
    .dataTitle {
        font-size: 15px;
    }
    .footerContainer , .footer{
        max-width: 970px;
    }
}

@media (max-width: 990px) and (min-width:768px) {
.footerDataWrap:first-child{
    margin-left: 12px;
}
.footerDataWrap:last-child{
    margin-right: 12px;
}
}
@media (max-width: 767px) {
    .footerContainer, .footer{
        max-width: 750px;
    }
    .footerContainer{
        padding: 40px 0px;
    }
    .footer {
        flex-direction: column;
        justify-content: center;
    }
    .copyrightText{
        padding-bottom: 15px;
        width: 100%;
        text-align: center;
    }
    .iconWrapper{
        justify-content: center;
        width: 100%;
    }
    .footerDataWrap {
        width: 50%;
        padding: 0px 0px 0px 25px;
    }
    .dataUl{
        margin-bottom: 30px;
    }
}

@media (max-width: 550px) {
    .footerDataWrap {
        width: 100%;
        padding: 0px 0px 0px 35px;
    }
}