.tabcontent {
  padding: 20px;
  border: 1px solid #ccc;
  width: 78%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  color: #333;
}
.deleteAccountWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.deleteAccountIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.heading {
  font-size: 20px;
  font-weight: 600;
}
.deleteAccountUl {
  margin-bottom: 0;
  padding-left: 20px;
}
.deleteAccountUl li {
  padding-bottom: 15px;
  list-style: disc;
}
.deleteAccountUl li p {
  font-size: 16px;
}
.agreeText {
  display: flex;
  align-items: center;
  gap: 12px;
}
.agreeText input {
  margin: 0;
}
.agreeText p {
  font-size: 14px;
}
.deleteAccountBtnsWrapper {
  width: 100%;
  display: flex;
  gap: 20px;
}
.primaryBtn {
  background-color: #eb2675 !important;
  border: 1px solid #eb2675;
  color: #fff !important;
}
.secondaryBtn {
  background-color: transparent !important;
  border: 1px solid #eb2675;
  color: #eb2675 !important;
}
.primaryBtn,
.secondaryBtn {
  border-radius: 0;
  padding: 15px 20px !important;
  font-size: 14px !important;
  line-height: normal;
  font-weight: 600;
  width: 100% !important;
  text-transform: uppercase !important;
  text-align: center !important;
}
.primaryBtn:hover {
  background-color: transparent;
  color: #eb2675;
}
.primaryBtn:focus {
  background-color: transparent;
  color: #eb2675;
  outline: 0;
}
.secondaryBtn:hover {
  background-color: #eb2675;
  border: 1px solid #eb2675;
  color: #fff;
}
.secondaryBtn:focus {
  outline: 0;
  background: #eb2675;
  color: #fff;
}
input[type="checkbox"] {
  accent-color: #eb2675;
}
@media (max-width: 767px) {
  .tabcontent {
    width: 100%;
    padding: 20px 0 0;
  }
}
@media (max-width: 550px) {
  .primaryBtn,
  .secondaryBtn {
    font-size: 12px !important;
    padding: 10px !important;
  }
}
