.pageNotFoundWrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding-bottom: 100px;
}
.para{
    font-size: 36px;
    line-height: normal;
    text-align: center;
}
.pageNotFoundWrapper svg {
    width: 100%;
}
.btn {
    background-color: #eb2675;
    border: 1px solid #eb2675;
    color: #fff;
    border-radius: 0;
    padding: 15px 20px;
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    width: max-content;
    text-transform: uppercase
}
.btn:hover {
    background-color: transparent;
    color: #eb2675;
}
@media (max-width: 991px) {
    .para {
        font-size: 28px;
    }
}
@media (max-width: 767px) {
.pageNotFoundWrapper svg {
    height: 100%;
}
.para {
    font-size: 20px;
    padding: 0 50px;
}
}