.blog_detail_banner_section img{
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    max-width: 100% !important;
    margin-left: -18px;
}
.blog_detail_banner_section .blogdetail_header_section h1 {
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 58px;
    line-height: normal;
    letter-spacing: -3.5px;
    font-weight: 400;
}
.blog_detail_banner_section {
    height: 500px;
    overflow: hidden;
    position: relative;
}
.blogdetail_header_section {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 20px 50px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 70%;
}
.title_and_date{
    display: flex;
    align-items: center;
    gap: 16px;
}
.blogdetail_header_section .title_and_date p {
    font-size: 16px;
}
.blogdetail_header_section h1 {
    text-align: left !important;
}
.blog_inner_page_content_section .blogdetail_content {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 35px;
}
.blog_inner_page_content_section .blogdetail_content p {
    font-size: 16px;
}
.blogdetail_header_section .title_and_date .card_date{
    border-left: 1px solid #AFAFAF;
    padding: 0px 15px;
}
.blog_detail_middle_image {
    height: 400px;
    overflow: hidden;
}
.blogdetail_tag_section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding-bottom: 80px;
}
.blogdetail_tag_section a:hover p {
    border: 1px solid #EB2675;
}
.related-blogs-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
 #wrapper {
    width: 100%;
    max-width: 100%;
    position: relative;
}
 #carousel {
    overflow: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
}
 #carousel::_webkit_scrollbar {
    height: 0;
}
 #prev,  #next {
    display: flex;
    justify-content: center;
    align-content: center;
    align-content: center;
    background: white;
    padding: 2px;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    position: absolute;
    border: 1px solid #EB2675;
    z-index: 9999;
}
 #prev {
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);
    display: none;
}

 #next {
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
 #content {
    display: grid;
    grid-gap: 16px;
    grid-auto-flow: column;
    margin: auto;
    box-sizing: border-box;
}
 .item {
    width: 180px;
    height: 180px;
    background: green;
}
 #wrapper button i.pe_7s_angle_right,  #wrapper button i.pe_7s_angle_left {
    color: #EB2675;
    font-size: 40px;
    font-weight: 600;
}
 p.heading {
    font-size: 26px;
    padding-bottom: 25px;
    font-weight: 600;
}
 #content .card {
    width: 369px;
}

.blogdetail_tag_section a p {
    display: flex;
    padding: 8px 15px;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #AFAFAF;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 550px) {
    .blog_detail_banner_section .blogdetail_header_section h1 {
        font-size: 35px;
        letter-spacing: -1px;
    }
}

@media (max-width: 767px) {
    .blog_detail_banner_section {
        height: 100%;
    }
    .blogdetail_header_section {
        position: relative;
        max-width: 100%;
    }
}