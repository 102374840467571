.blogsHeader{
    height: 435px;
    width: 100%;
    background-image: url('../../../public/images/blog-bg-image.svg');
    /* display: flex;
    justify-content: center; */
}
.blogsHeader button{
    padding: 8px 15px;
    border-radius: 25px;
    background: #EB2675;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    border: none;
}

.blogsHeader h1{
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 60px;
    line-height: normal;
    letter-spacing: -3.5px;
    font-weight: lighter;
}
.blogsHeader p{
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

.HeaderContent{
    display: flex;
    width: 610px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    padding: 80px 0;
    background: radial-gradient(#fff, transparent);
    text-align: center;
}
@media only screen and (max-width: 768px) {
    .HeaderContent{
        width: 400px;
    }
    .blogsHeader{
        margin: 0;
        color: #000;
        text-align: center;
        font-size: 60px;
        line-height: normal;
        letter-spacing: -3.5px;
        font-weight: lighter;
    }
}
