.orderList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
}
.tabcontent {
    padding: 20px;
    border: 1px solid #ccc;
    width: 78%;
    border-left: none;
    border-bottom: none;
    border-right: none;
  }
.orderList .list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #F5F5F5;
    padding: 20px;
    border-bottom: 1px solid #EDEDED;
}
.couponList {
    padding: 0;
    gap: 20px;
}
.couponList .list {
    width: 49%;
}
.couponContentSection {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: fit-content;
}
.couponList .copunListSection {
    display: flex;
    gap: 20px;
}
.couponContentSection img.couponContentImage {
    width: 170px;
    height: 170px;
    object-fit: cover;
}
.couponContentSection .couponContent {
    display: flex;
    flex-direction: column;
}
img.coupon-icon {
    width: 40px;
}
.couponContentSection .couponContent p.flatDiscountText {
    font-size: 22px;
    color: #eb2675;
    padding-top: 20px;
}
.couponContentSection .couponContent p.couponCodeText {
    display: flex;
    gap: 6px;
    align-items: center;
    padding-top: 10px;
}
.couponContentSection .couponContent p.couponCodeText i.pe7sCopyFile {
    cursor: pointer;
    font-size: 18px;
}
.couponContentSection .couponContent a {
    display: flex;
    align-items: center;
    gap: 0px;
    width: fit-content;
    color: #eb2675;
    font-size: 16px;
    padding-top: 20px;
    cursor: pointer;
}
.couponContentSection .couponContent a i.pe7sAngleRight {
    font-size: 26px;
}
.maximumSavingContent {
    width: fit-content;
    display: flex;
    gap: 12px;
}
.maximumSavingContent p {
    font-size: 16px;
}
.couponcodesection .copucodetextsection p.save-money-text {
    padding: 12px 0;
    font-size: 16px;
}
.couponcodesection input {
    cursor: pointer;
}
.couponcodesection .copucodetextsection p {
    font-size: 14px;
}
.copuncodetextwithborder {
    padding: 9px;
    border: 1px solid #eb2675;
    width: fit-content;
    font-size: 16px;
    color: #eb2675;
    font-weight: 600;
}
.saveMoneyText{
    padding: 12px 0px;
    font-size: 16px;
}
.couponSubText{
    font-size: 14px;
    font-weight: 400;
}
.couponcodesection .copucodetextsection {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.couponCodeList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 15px;
}
.couponCodeList .couponcodesection {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background: #F5F5F5;
    padding: 20px;
    border-bottom: 1px solid #EDEDED;
    align-items: flex-start;
}
.couponInputBoxSection input.couponInputBox {
    background: transparent;
    border: transparent;
}
.couponInputBoxSection input.couponInputBox:focus-visible{
    border: 0;
    outline: 0;
}
.couponInputBoxSection i.pe-7s-ticket {
    font-size: 20px;
}
@media (max-width: 767px) {
    .tabcontent{
        width: 100%;
    }
    .copunListSection{
        flex-wrap: wrap;
    }
    .couponList .list {
        width: 100%;
    }
}
.modalFooter {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }