

/* .Slider button{
    background-color: #ccc;
} */

.Slider .slick-prev:before, .Slider .slick-next:before {
    font-family: 'slick';
    font-size: 50px;
    line-height: 1;
    opacity: .75;
    color: #ccc;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.card {
    flex-basis: 100%;
    border-radius: 4px;
    border: 1px solid #DDD;
    background: #FFF;
    min-height: 700px;
    cursor: pointer;
    margin-bottom: 20px;
    height: 100%;
}
 .card:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
 .card .image_section img {
    object-fit: cover;
    height: 300px;
    width: 100%;
    border-radius: 4px 4px 0 0;
}
 .card .card_content {
    padding: 24px;
    z-index: 999;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background: #fff;
    border-radius: 0 0 4px 4px;
}
 .card .card_content .title_and_date {
    display: flex;
    align-items: center;
    gap: 16px;
}
 .card .card_content .title_and_date p.title {
    color: #EB2675;
    font-size: 14px;
    font-weight: 700;
}
 .card .card_content .title_and_date .card_date {
    border-left: 1px solid #AFAFAF;
    padding: 0px 15px;
}
 .card .card_content .title_and_date .card_date p {
    font-size: 14px;
}
 .card .card_content .heading_para {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
 .card .card_content .heading_para p.heading {
    font-size: 26px;
    font-weight: 700;
}
 .card .card_content .heading_para p.para {
    font-size: 16px;
}
 .card .card_content button.learn_more_btn {
    color: #EB2675;
    font-size: 14px;
    font-weight: 700;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    border: none
}


 /* .card .card_content button.learn_more_btn {
    font-size: 28px;
} */

@media only screen and (max-width: 768px) {
    .blogFilterPanel{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0rem;
        flex-direction: column;
        gap: 20px;
    }

    .blogFilterPanel ul li{
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .Search{
        display: flex;
        width: 51%;
    }
}

@media only screen and (max-width: 1024px) {
    .blogFilterPanel{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0rem;
        flex-direction: column;
        gap: 20px;
    }

    .blogFilterPanel ul li{
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .Search{
        display: flex;
        width: 51%;
    }
}