.logo {
  width: 100px;
}
.headerRight {
  color: #5a5254;
  font-weight: 500;
  display: flex;
  position: relative;
  align-items: center;
}
.headerRight button {
  background-color: unset;
  border: none;
  color: #5a5254;
  font-size: 20px;
  padding: 0.5rem;
  position: relative;
}
.headerRight button:hover {
  color: #eb2675 !important;
}
.activeIcon svg path {
  fill: #eb2675 !important;
}
.profileActiveIcon svg path {
  fill: #eb2675 !important;
}
.userButton {
  font-size: 26px !important;
}
.headerRight button span {
  position: absolute;
  text-align: center;
  font-size: 8px;
  background-color: #eb2675;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: #fff;
  top: 11px;
  right: 0px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerRight button svg {
  font-size: 1.5rem;
}
.headerRight button:hover,
:focus,
:visited,
:active {
  background-color: unset;
  border: none;
}

.navForm {
  width: 33%;
}
.flag {
  font-size: smaller;
}
.navForm input {
  width: 100%;
  background-color: #f4f5f6;
  padding: 8px 10px;
  outline: none;
  border: none;
}
.navForm span {
  margin: auto;
  border: 0;
  outline: 0;
  width: 20px;
  font-size: 1rem;
  margin: auto;
  border-radius: 5px 0px 0px 5px;
}
.mega_menu {
  position: relative;
}
.dropDown_menu {
  display: flex;
}
.headerRight .mega_menu .mega_wrap {
  right: 0 !important;
  left: unset;
}
.mega_wrap {
  position: absolute;
  width: 250px;
  left: 0;
  top: 100%;
  background: #fff;
  box-shadow: 0px 0px 5px 1px #ccc;
  display: block;
}
.mega_menu .mega_wrap {
  display: none;
  animation: growDown 500ms ease-in-out forwards;
  transform-origin: top center;
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.mega_menu:hover .mega_wrap {
  display: block;
  height: auto;
  transform: scale(1, 1);
}
.hideMenu{
  display: none !important;
}

.mega_wrap {
  padding: 20px;
}
.mega_wrap .row ul.profilelisting {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #eaeaec;
}

.mega_wrap .row ul.profilelisting {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #eaeaec;
  margin-bottom: 0px;
}
.mega_menu {
  text-transform: capitalize;
  font-size: 15px;
}
.welcome_section p:first-child {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 0px !important;
}
.welcome_section p {
  font-size: 12px;
  margin-bottom: 0px !important;
}

.mega_wrap .row .welcome_section {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.loginButton:hover {
  background-color: #eb2675;
  transition: linear 0.1s;
  color: #fff;
  text-decoration: none;
}

.loginButton {
  color: #eb2675;
  border: 1px solid #eb2675;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 14px;
  width: fit-content;
  margin: 6px 0;
  text-decoration: none !important;
  cursor: pointer;
}
.loginButton a {
  color: #eb2675 !important;
}
.loginButton:hover a {
  color: #fff !important;
}
.profilelisting li p {
  color: #5a5254;
  cursor: pointer;
}
.profilelisting li p:hover {
  color: #eb2675;
}
.iconBtn{
  height: 100%;
  cursor: pointer;
}
.iconBtn svg ,.userButton svg {
 width: 20px;
 height: 20px;
  stroke: #ccc;
}

.iconBtn svg:hover path ,.userButton svg:hover path {
  fill: #eb2675;
}
.nav-link {
  color: #5a5254;
  font-size: 14px;
}
.navbarWrapper {
  padding: 0px 28px;
  box-shadow: 0 0 5px #ddd;
  z-index: 1000;
  background-color: #fff;
  position: sticky;
  top: 0;
}
.megaWrapDropdown a {
  padding: 0px !important;
}
.searchIconBtn {
  margin: unset !important;
  display: flex;
}
.searchInputWrapper {
  background-color: #f4f5f6;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  justify-content: flex-start;
  margin-right: 25px;
  margin-left: 71px;
}
.searchInputWrapper form {
  width: 100%;
}
/* navbar laptop responsive--------------------- */
@media only screen and (max-width: 1440px) {
  .navForm {
    width: 40%;
  }
}

@media only screen and (max-width: 1200px) {
  .navForm input {
    max-width: 200px;
  }
  .navForm span {
    margin: auto 0px;
  }
  .headerRight {
    justify-content: flex-end;
  }
  .navForm {
    width: fit-content;
  }
  .searchInputWrapper{
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1090px) {
  .navForm input {
    max-width: 130px;
    display: none;
  }
  .searchInputWrapper{
    background-color: unset;
  }
}
@media only screen and (max-width: 992px) {
  .navForm span {
    background-color: transparent;
    padding: unset;
    display: flex;
    align-items: center;
    width: auto;
  }
  .navbarWrapper {
    padding: 0px 5px;
  }
  .searchInputWrapper {
    background-color: transparent;
    margin: 0px;
  }
  .headerRight button {
    padding: 0.4rem;
  }
}
.radio_input {
  margin: 0;
  accent-color: #eb2675;
  cursor: pointer;
  margin-top: 5px;
  display: block !important;
}
@media only screen and (max-width: 1180px) {
  .nav-link {
    font-size: 13px;
  }
  nav {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/* navbar mobile responsive--------------------- */
@media only screen and (max-width: 600px) {
  .headerRight button {
    background-color: unset;
    border: none;
    color: #5a5254;
    padding: 2px;
    margin: auto 2px;
  }
  .headerRight button svg {
    font-size: 1.2rem;
    padding: 1;
  }
  .headerRight button span {
    top: 5px;
    right: -5px;
  }
  .logo {
    width: 70px;
  }
}

@media only screen and (max-width: 790px) {
  .searchInputWrapper{
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .navbarWrapper {
    background-color: #f5f5f5;
  }
  .radio_input {
    display: block !important;
  }
  .currency_text{
    display: none;
  }
}
