.container {
    width: 1170px;
    margin: auto;
    padding: 20px 15px 100px;
}

.helpHeadingWrapper {
    display: flex;
    width: 100%;
}

.helpTitleWrapper {
    display: flex;
    padding: 40px 20px 40px 0;
    gap: 4px;
    flex-direction: column;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 22%;
}

.heading {
    font-size: 18px;
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
}

.name {
    font-size: 16px;
    font-weight: 600;
}

.helpHeaderWrapper {
    display: flex;
    width: 78%;
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.helpHeader {
    width: 100%;
    display: flex;
    gap: 50px;
    justify-content: space-between;
}

.searchWrapper {
    display: flex;
    align-items: center;
    width: 40%;
}

.searchWrapper span {
    position: relative;
    right: 0;
    background-color: #f4f5f6;
    border-radius: 5px 0px 0px 5px;
    ;
    border: none;
    min-height: 40px;
    padding: 0px 10px;
}

.searchWrapper span i {
    font-size: 20px;
    padding: 10px 0px;
}

.searchWrapper input {
    position: relative;
    max-width: 100%;
    width: 100%;
    right: 0;
    background-color: #f4f5f6;
    border-radius: 0px 5px 5px 0px;
    border: none;
    min-height: 40px;
    padding: 0px 10px;
    outline: none;
}

.HeaderAccountBtn {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #ccc;
    padding: 1rem;
    max-width: 425px;
    width: 60%;
}

.accountWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.HeaderAccountBtn svg {
    width: 35px;
    height: 35px;
    overflow: visible !important;
}

.HeaderAccountBtn p {
    font-size: 18px;
    line-height: normal;
}

.HeaderAccountBtn a {
    background-color: transparent !important;
    border: 1px solid #eb2675 !important;
    color: #eb2675 !important;
    border-radius: 0 !important;
    padding: 15px 20px;
    font-size: 14px;
    line-height: normal !important;
    font-weight: 600 !important;
    width: max-content;
    font-weight: 600 !important;
}

.tabContainer {
    display: flex;
}

.tab {
    width: 22%;
    border-right: 1px solid #ccc;
    padding-right: 20px;
}

.tabDetailsContainer {
    display: flex;
    width: 78%;
    padding: 20px;
}

.tablinks {
    display: block;
    width: 100%;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    border-radius: 0;
    padding: 20px;
    border: none;
    background: transparent;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 1px solid #dbdbdb;
}

.tablinks:hover {
    color: #eb2675 !important;
    font-weight: 600;
}

@media (max-width: 1365px) {
    .searchWrapper input {
        max-width: 320px !important;
    }
}

@media (max-width: 1199px) {
    .container {
        width: 970px;
    }

    .tablinks {
        padding: 20px 0;
    }

    .searchWrapper input {
        max-width: 200px;
    }
}

@media (max-width: 1059px) {
    .searchWrapper input {
        max-width: 200px;
    }
}

@media (max-width: 992px) {
    .container {
        width: 750px;
    }

    .helpHeader {
        gap: 20px;
    }
    .HeaderAccountBtn{
        justify-content: space-between;
    }
}

@media (max-width: 767px) {
    .container {
        width: 100%;
        padding: 20px 19px 100px;
    }

    .helpTitleWrapper {
        flex-direction: row;
        width: 100%;
        border-right: none;
        padding: 20px 0px;

    }

    .helpHeadingWrapper {
        flex-direction: column;
    }

    .helpHeaderWrapper {
        width: 100%;
    }

    .accordionHeader {
        font-weight: 400;
    }

    .helpTitleWrapper {
        align-items: center;
    }
    .name {
        font-weight: 400;
        color: #333;
    }
}

@media (max-width: 550px) {
    .helpHeader {
        flex-direction: column;
    }

    .searchWrapper,
    .HeaderAccountBtn {
        width: 100%;
    }

    .searchWrapper input {
        width: 100%;
        max-width: unset !important;
    }

    .HeaderAccountBtn {
        gap: 12px;
        justify-content: unset;
        max-width: unset;
    }

    .accountWrapper {
        justify-content: space-between;
        width: 100%;
    }

    .helpHeaderWrapper {
        padding: 20px 0;
    }

}