.fixedBanner.aboutBanner {
  background: url("./banner-3.png") no-repeat top center;
}
.aboutBanner h2 {
  background-color: #fff;
  font-weight: 300;
  margin: 2px 0;
  text-transform: uppercase;
  display: inline;
  padding: 0 10px;
  font-weight: 600;
  line-height: 1.75;
  letter-spacing: 1px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  -moz-box-decoration-break: clone;
}
.aboutTop {
  margin-bottom: -8px;
}
.story .contentWrap {
  column-count: 2;
  column-gap: 30px;
  position: relative;
  margin: 0;
  padding-bottom: 50px;
}

.quote h4 {
  margin: 30px 0;
  padding: 0 65px;
  color: #fff;
  font-style: italic;
  position: relative;
}

.quote h4:before,
.quote h4:after {
  content: "";
  display: inline-block;
  width: 67px;
  height: 58px;
  position: absolute;
}
.primaryPadding {
  padding: 100px 0;
}

.leaderBlock figure {
  margin-bottom: 33px;
}

.leaderBlock h4 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 300;
}

.leaderBlock h4 span {
  font-weight: 500;
}
.primaryBg {
  background-color: #eb2675;
}
.leaderBlock .socialIcons {
  margin-top: 10px;
}

.quote h4:before {
  background: url(./quote-left.png) no-repeat;
  left: -10px;
  top: -20px;
}

.quote h4:after {
  background: url(./quote-left.png) no-repeat;
  right: -10px;
  bottom: -20px;
  transform: rotate(180deg);
}
.story .contentWrap:after {
  display: block;
  margin: 0 auto;
  width: 240px;
  height: 5px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.founder span {
  display: block;
  font-size: 35px;
}
.fontDafoe {
  font-family: "Mr Dafoe", cursive;
}
.pPb {
  padding: 100px 0;
}
.fontWt300 {
  font-weight: 300;
}
.founderBlock .rightBlock .primaryPadding {
  padding-bottom: 30px;
}
.aboutBanner h2 span {
  font-weight: 500;
}
.about-banner .banner-overlay:before {
  background: rgba(0, 0, 0, 0.2);
}
.aboutBanner h2 {
  font-size: 35px;
}
.bannerContent {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.contentWrap {
  margin: 0 0 1.5em;
}
.bannerContent .contentWrap {
  display: table;
  width: 100%;
  text-align: center;
}

.bannerContent .contentWrap .inner {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 3;
}
ul.socialIcons {
  margin: 0;
  padding: 0;
}

ul.socialIcons li {
  display: inline-block;
  margin-right: 2px;
}

ul.socialIcons li a {
  display: block;
  width: 36px;
  padding: 0.51em 0;
  color: #979494;
  text-align: center;
  border: 1px solid #979494;
  line-height: 1;
}

ul.socialIcons li a:hover {
  color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  border-color: transparent;
}

footer ul.socialIcons li a {
  line-height: 1.4;
}
