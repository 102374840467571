.section{
    min-height: 100vh;
    background: #e827744a;
}
.formContainer{
    background-color: #fff;
    box-shadow: 0 0 30px rgb(0 0 0 / 5%);
    padding: 50px;
}

.formContainer h4{
    clear: both;
    margin: 0 0 20px;
    line-height: 1.4;
    font-weight: 500;
    color: #333
}
.login_form p.login_username {
    margin: 0;
    padding-bottom: 25px;
}

.inputGroup label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 0.9rem;
}
.inputGroup{
    padding-bottom: 25px;
}
.inputGroup input{
    background-color: #fff;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border-radius: 0;
    color: #7a8081;
    padding: 0.67em 0.5em;
    border: 1px solid #9d9a9b;
    width: 100%;
    line-height: 1;
}
.countrySelector{
    background-color: #fff;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border-radius: 0;
    color: #7a8081;
    padding: 0.67em 0.5em;
    border: 1px solid #9d9a9b;
    width: 100%;
    line-height: 1;
}
.Mobile{
    display: flex;
    gap: 20px;
}
.Mobile button{
    border: 0;
}
.inputGroup .submitButton{
    background: #1a1a1a;
    border: 0;
    border-radius: 4px;
    color: #fff;
    letter-spacing: 0.046875em;
    padding: 0.84375em 0.875em 0.78125em;
    text-transform: uppercase;
    width: 100%;
}