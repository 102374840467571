.tabcontent {
  padding: 20px;
  border: 1px solid #ccc;
  width: 78%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  color: #333;
}
.saveCardsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 500px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.heading {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
}
.para {
  font-size: 16px;
  text-align: center;
}
.savedCardsContent {
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.addCardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addCardWrapper p {
  font-size: 16px;
  font-weight: 600;
}
.allCardsWrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.cards {
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  width: 100%;
  background: #f5f5f5;
}
.cardDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cardDetails p {
  font-size: 18px;
}
.cardLogoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.cardLogoWrapper p {
  font-size: 15px;
}
.addNewCardBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px !important;
  border-radius: 4px !important;
  cursor: pointer;
  background: transparent;
  color: #eb2675 !important;
  line-height: 1 !important;
  border-radius: 4px;
}
.addNewCardBtn:hover {
  background: transparent;
  border: 1px solid #eb2675;
  color: #eb2675;
  outline: 0;
}
.removeBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675 ;
  padding: 8px 16px 7px !important;
  background-color: transparent;
  color: #eb2675 !important;
  cursor: pointer;
  width: max-content !important;
  line-height: 1 !important;
  border-radius: 4px !important;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .tabcontent{
    width: 100%;
    padding: 0;
  }
  .saveCardsWrapper{
    width: 100%;
  }
  .cards {
    padding: 16px;
  }
}
@media (max-width: 550px) {
  .addCardWrapper p {
    width: 100%;
  }
  .addNewCardBtn{
    font-size: 12px !important;
    width: max-content !important;
    white-space: nowrap;
  }
}