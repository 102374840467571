.section__Jobs-styledContent {
  text-align: left;
}

.section__Jobs-styledContent h4 {
  color: #eb2675;
  font-size: 22px;
  font-weight: 500;
}

.section__Jobs-styledContent h5 {
  font-size: 13px;
  font-family: "Roboto Mono", monospace;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
  color: #64ffda;
}

.section__Jobs-styledContent p {
  font-family: 'Arimo', sans-serif;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
}

.section__Jobs-detail::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: #eb2675;
}

@media (max-width: 1199px) {
  .specifications-list .points {
      flex-basis: 30%;
  }
}
@media (max-width: 999px) {
  .specifications-list .points {
      flex-basis: 28% !important;
  }
}
@media (max-width: 767px) {
  .specifications-list {
      padding-top: 15px;
      gap: 15px;
  }

.specifications-list .points {
      gap: 0px;
  }
}

