.wishListNoDataWapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 100%;
    min-height: inherit;
    margin: auto;
  }
  .wishListNoDataTitle {
    font-size: 24px;
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
  }
  .wishListNoDataSubTitle{
   font-size: 16px;
   max-width: 380px;
   text-align: center;
  }
  .wishListNoDataBtn {
    padding: 0.95em 2.4em;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    border-radius: 0;
    background-color: #eb2675;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #eb2675;
    text-transform: uppercase;
    margin-top: 25px;
  }
  .wishListNoDataBtn:hover {
    color: #eb2675;
    background-color: #fff;
  }