.breadcrumbBg{
    background: #F5EBEF;
    margin-bottom: 20px;
  }
  .breadcrumbsAndTittle {
    padding: 0px 15px;
  }
  .rowWrapper{
    width: 90%;
    max-width: unset;
  }
  .rowBase .breadcrumbsBase ul.breadcrumbsList {
    list-style: none;
    margin: 0;
    display: flex;
    gap: 8px;
  }
  .woocommerceBreadcrumbWrapper{
    display: flex;
    gap: 8px;
    background-color: transparent !important;
    align-items: center;
  }
  .woocommerceBreadcrumb {
    padding: 10px 0;
    display: flex;
    gap: 8px;
    background-color: transparent !important;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .breadcrumbsText {
    display: inline-block;
    color: #eb2675;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
  }
  .productItem{
    padding: 10px 0;
  }
  .woocommerceBreadcrumb .breadcrumbsText::after {
    content: ">";
    display: inline-block;
    margin: 0 3px 0 6px;
    color: #5a5254;
  }
  .woocommerceBreadcrumb :last-child::after {
    content: '';
    display: none;
    margin: 0 3px 0 6px;
    color: #5a5254;
  }
  
  .woocommerceBreadcrumb a:hover {
    color: #5a5254;
  }