
.review{
   padding: 25px 0px 0px 30px;
   display: flex;
   flex-direction: column;
   gap: 30px;
}
.reviewDetailsWtapper {
   display: flex;
   flex-direction: column;
   gap: 10px;
   border-bottom: 1px solid #eee;
   padding-bottom: 25px;
}
.reviewDetailsWtapper:last-child {
   border-bottom:unset
}
.review ul{
   padding: 0; 
   margin-bottom:0
}

.review ul li{
   display: inline;
   padding-left: 12px;
   font-size: 14px;
   color: #333;
 }
 .review ul li:first-child{
    border-right: #ccc solid 2px;
    padding-right: 20px;
    padding-left: 0px !important;
  }
 .review ul li svg{
    font-size: 17px;
    color:#eb2675;
 }
 .name{
   padding-top: 15px;
   text-transform: capitalize;
 }

 .review .accordion .accordion-item .accordion-header .accordion-button::after{
    display: none !important;
 }
 .viewBtn {
   width: auto;
   color: #eb2674;
   font-size: 16px;
   font-weight: 700;
   cursor: pointer;
}
.title {
   color: #333;
   font-size: 24px !important;
}
@media (max-width: 767px) {
   .review{
       padding: 0px 18px 20px;
   }
   .title{
      font-size: 18px !important;
   }
}