body {
  font-family: "Arimo", sans-serif !important;
  font-size: 0.9rem;
  font-weight: 500;
  color: #5a5254;
}
p {
  margin-bottom: 0 !important;
}
.border-top {
  border-top: ipx solid #ccc;
}
a:hover {
  color: #eb2675 !important;
}
a {
  text-decoration: none;
  color: #333;
}
/* svg{
  vertical-align: baseline !important;
} */
.box-shadow {
  box-shadow: 0px 0px 5px 1px #ccc;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: unset;
  border-color: unset;
}
#userDropDown {
  top: 100%;
  left: -115px !important;
  margin-top: var(--bs-dropdown-spacer);
}

.primary-color {
  color: #eb2675;
}
.navbar-toggler {
  border: none;
}
.nav-link {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.section {
  margin: 3rem 0rem;
}

.navbar-toggler:focus {
  border: none !important;
  box-shadow: none !important;
}

.navbar-toggler {
  padding: 0 !important;
  margin-right: 10px;
}

.dropdown-menu {
  border: none;
  border-radius: 0;
}
.dropdown-item {
  font-size: 0.95rem !important;
  line-height: 1.5;
}
.custom-select-label-currency{
  width: fit-content;
  display: flex;
  align-items: center;
}
nav {
  background-color: #fff !important;
}

.image-gallery-svg {
  height: unset;
  width: 25px !important;
}
.image-gallery-slide-wrapper:hover .image-gallery-icon{
  display: block !important;
}
.image-gallery-icon {
  color: #eb2675 !important;
  display: none;
}

footer {
  background-color: #f5f5f5;
}
footer h4 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 25px;
}
footer ul {
  padding-left: 0.6rem;
}
footer ul li {
  list-style: none;
  position: relative;
  font-size: 0.85rem;
  padding: 3px 0px 3px 0px;
}
/* footer ul li::before {
  content: "-";
  display: inline-block;
  position: absolute;
  left: -10px;
  top: 2px;
} */

footer form input {
  border: 1px solid #fff;
  border-radius: 0;
  padding: 8px;
  width: 80%;
}
footer form button {
  border: 1px solid #fff;
  border-radius: 0;
  padding: 8px;
  background-color: #eb2675;
  color: #fff;
  width: 20%;
}
.scrollUpBtn{
  position: fixed;
  right: 10px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #eb2675 !important;
  line-height: 1.3;
  font-size: 28px;
  text-align: center;
  z-index: 100;
  border-radius: 5px;
  cursor: pointer;
}
.accordion-item {
  border-bottom: 1px solid #ccc !important;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0px !important;
  padding: 0px !important;
}
.accordion-item button {
  background: transparent;
  color: #000;
  text-transform: none;
  font-size: 16px;
  line-height: normal;
  width: 100%;
  text-align: left;
  padding: 20px 30px 20px 0px;
  background-color: transparent;
}
.accordion-body {
  padding-left: 0px !important;
}
.accordion-button:not(.collapsed) {
  color: #333;
  background-color: transparent;
  box-shadow: unset;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:focus {
  border: none;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 50px !important;
  line-height: 1;
  opacity: 1;
  color: #eb2675 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next {
  right: 50px !important;
}

.slick-prev {
  left: 21px !important;
  z-index: 9;
}

.container-fluid .slick-active div {
  background-color: #eb2675 !important;
}
.slick-dots ul {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.slick-dots div {
  width: 30px;
  margin: 0px 3px;
  height: "3px";
  background-color: #afafaf;
}
.reviewSlider .slick-slider {
  width: 100%;
}
.reviewSlider .slick-track {
  display: flex;
  gap: 16px;
}
.adBanner .modal-content {
  width: fit-content;
  background-color: #fff !important;
  border: none;
}
.adBanner .modal-dialog,
.editprofileModal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.editprofileModal .modal-dialog {
  width: max-content !important;
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  max-width: unset;
  outline: none !important;
}
.editprofileModal .modal-content {
  border-radius: 0px;
  background-color: #fff !important;
}
.image-gallery-thumbnails-container {
  text-align: start !important;
  margin-top: 15px;
}
.image-gallery-thumbnails {
  padding: 0 !important;
}
.image-gallery-thumbnail.active {
  opacity: 1;
}
.image-gallery-thumbnail {
  opacity: 0.5;
  border: none !important;
  width: 117px !important;
  height: 117px !important;
}
.image-gallery-thumbnail-image {
  width: 117px !important;
  height: 117px !important;
  object-fit: cover;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
  gap: 16px;
  /* flex-wrap: wrap; */
}
.image-gallery-slides{
  background-color: #F5EBEF !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(80vh - 50px) !important;
}
.col-sm-3,
.col-sm-9 {
  padding: 0px;
}
.recommendedProductSlider {
  display: flex;
  align-items: center;
  justify-content: center;
}
.recommendedProductSlider .slick-track {
  display: flex;
  gap: 20px;
}
.recommendedProductSlider .slick-dots ul {
  gap: 6px;
}
.recommendedProductSlider .slick-dots li {
  margin: 0px;
}
.recommendedProductSliderUL li div {
  margin: 0 !important;
  margin-top: 20px !important;
}

.recommendedProductSliderUL .slick-active div {
  border: 2px solid #eb2675 !important;
  position: relative;
}
.recommendedProductSliderUL .slick-active div::before {
  content: "•";
  font-size: 20px;
  font-family: "Fontawesome";
  line-height: 1;
  position: absolute;
  left: 4.3px;
  top: -3.6px;
  color: #eb2675;
}
.productDescriptionAccordion .accordion-button::after {
  position: absolute;
  left: 0;
  content: "+";
  background-image: unset;
}
.productDescriptionAccordion .accordion-button:not(.collapsed)::after {
  content: "-";
  background-image: unset;
  transform: unset;
}
.productDescriptionAccordion .accordion-button {
  padding-left: 20px !important;
  font-weight: 600;
  color: #5a5254 !important;
}
.productDescriptionAccordion .accordion-body {
  padding: unset !important;
}
.profileAccordion .accordion-body {
  padding: unset !important;
}
.profileAccordion .accordion-header button {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding-bottom: 10px;
  padding-right: 30px;
  margin-bottom: 0px;
}

.navbarAccrdionHeader .accordion-button::after {
  display: none;
}
.navbarAccrdion .accordion-item button {
  padding: 10px 0px;
  color: #5a5254;
}
.navbarAccrdion {
  padding: 0px 12px;
  overflow: auto;
}
.navbarAccrdion .accordion-button:not(.collapsed) {
  color: #eb2675;
}
.navbarAccrdion .accordion-body {
  padding: 5px 10px !important;
}
.navbarAccrdion .accordion-body p {
  padding: 10px 0px;
  font-size: 15px;
  color: #5a5254;
  border-bottom: 1px solid #ccc;
}
.navbarAccrdion .accordion-body p span {
  padding-right: 5px;
}
.navbarAccrdion .accordion-body div:last-child p {
  border-bottom: none;
}
.navbarAccrdion .accordion-item {
  background-color: transparent;
}
.searchModal {
  z-index: 9999999;
}
.searchModal .modal-dialog {
  top: 0;
  margin: 0px;
  width: 100%;
  max-width: unset;
  padding: 0;
}
.navbar-nav .nav-link:after {
  width: 100%;
  height: 1px;
  content: " ";
  display: block;
  transform: scaleX(0);
  opacity: 0;
}

.navbar-nav .nav-link:hover:after {
  width: 100%;
  height: 1px;
  content: " ";
  display: block;
  transform: scaleX(1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  background-color: #eb2675;
}
.active-link {
  color: #eb2675 !important;
}
.navbar-nav .active-link:after {
  width: 100%;
  height: 1px;
  content: " ";
  display: block;
  transform: scaleX(1);
  opacity: 1;
  background-color: #eb2675;
}
.helpAccordion .accordion-item {
  border: none;
}
.helpAccordion .accordion-body {
  padding: unset !important;
}
.helpAccordion .accordion-header button {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding-bottom: 10px;
  padding-right: 30px;
  margin-bottom: 0px;
}
.helpFaqAccordion .accordion-header button {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  padding: 20px 30px 20px 0px;
  margin-bottom: 0px;
}
.helpFaqAccordion .accordion-body {
  font-size: 13px;
  padding-bottom: 20px !important;
  color: #333;
}
.searchModal .modal-dialog {
  background-color: #fff;
  border: none;
}
.searchModal .modal-content {
  border-radius: unset;
  padding: 22px 15px;
}
.successStarBar .bg-success {
  background-color: #14958f !important;
}
.averageStarBar .bg-success {
  background-color: #72bfbc !important;
}
.twoStarBar .bg-danger {
  background-color: #fcb301 !important;
}
.oneStarBar .bg-danger {
  background-color: #f16565 !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #9d9a9b !important;
  box-shadow: none;
  border-radius: 3px !important;
}
.react-tel-input .flag-dropdown {
  border: 1px solid #9d9a9b !important;
}
.editprofileModal .react-tel-input .form-control {
  border-radius: 0px !important;
  background-color:#f4f5f6 !important;
  color:#7a8081 !important
}
.pagination{
  display: flex;
  gap: 8px;
}
.page-item a{
  text-align: center;
  line-height: 1;
  padding: 0.72em 1em;
  border: 1px solid #CCC;
  color: #333;
  background-color: #fff;
  margin: 2px;
  width: 42px;
  border-radius: 8px;
}
.page-item a:hover{
  color: #fff !important;
  background-color: #eb2675;
  border:1px solid #eb2675
}
.page-item,.break{
  display: flex;
  align-items: center;
}
.activePage a{
  text-align: center;
  line-height: 1;
  padding: 0.72em 1em;
  color: #fff !important;
  background-color: #eb2675;
  border:1px solid #eb2675
}
.productList .carousel,.productList .carousel-inner,.productList .carousel-item {
  height: 100%;
} 
@media only screen and (max-width: 767px) {
  .page-item a{
    width: auto;
  }
  .pagination{
    display: block;
    width: 100%;
    text-align: center;
  }
  .pagination li {
    display: inline-block;
  }
  .pagination li:first-child {
    display: block;
  }
  .pagination li:last-child {
    display: block;
  }
}
@media only screen and (max-width: 1040px) {
  .image-gallery-thumbnail,
  .image-gallery-thumbnail-image {
    width: 79px !important;
    height: 79px !important;
  }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    gap: 5px;
  }
}

@media only screen and (max-width: 1400px) {
  .nav-link {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media only screen and (max-width: 1180px) {
  .nav-link {
    font-size: 13px;
  }
  nav {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .navForm input {
    width: 150px;
  }
}

@media only screen and (max-width: 1180px) {
  .navbar-nav {
    color: rgb(90, 82, 84);
    flex-wrap: wrap;
    width: 420px;
  }
}

@media only screen and (max-width: 1180px) {
  .navbar-nav {
    color: rgb(90, 82, 84);
    flex-wrap: wrap;
    width: 350px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    top: 57px;
  }
}

@media only screen and (max-width: 992px) {
  nav {
    padding-left: 0 !important;
    padding-left: 0 !important;
  }
  .image-gallery-thumbnail,
  .image-gallery-thumbnail-image {
    width: 59px !important;
    height: 59px !important;
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    background-color: #f5f5f5 !important;
  }
  .accordion .accordion-item:last-child {
    border-bottom: 0 !important;
  }
  .adBanner .modal-content {
    width: 100%;
  }
  .recommendedProductSliderUL .slick-active div::before {
    top: -2.6px;
  }
  .editprofileModal .modal-content {
    width: 85%;
  }
  .editprofileModal .modal-dialog {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .recommendedProductSliderUL .slick-active div::before {
    top: -2.6px;
  }
}
@media only screen and (max-width: 500px) {
  .recommendedProductSliderUL .slick-active div::before {
    top: -3px;
  }
}
.navbar-nav a {
  font-size: 14px;
  color: #777;
  padding: 29px 12px !important;
}
.navbar-nav {
  justify-content: center !important;
  display: flex !important;
  width: 100% !important;
}
@media only screen and (max-width: 1200px) {
  .navbar-nav a {
    padding: 5px !important;
    font-size: 13px;

  }
}
@media only screen and (max-width: 992px) {
  .navbar-nav {
    justify-content: flex-start !important;
  }

  .navbar-nav a {
    padding: 5px !important;
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .navbar-nav a {
    padding: 10px 0px !important;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
