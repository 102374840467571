.modal {
  background-color: rgba(0, 0, 0, 0.7);
  color: #333;
}
.modalContent {
  border-radius: 0;
  padding: 0px 15px;
  margin: 15px 0px;
  width: 600px;
  max-height: 85vh;
  overflow: auto;
}
.modalHeader {
  margin: 0;
}
.modalHeaderWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  align-items: center;

}
.closeBtn {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  opacity: 1;
  border-radius: 50%;
  background-color: #eb2675;
  border: 1px solid #eb2675;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  display: flex
}
.closeBtn:hover {
  opacity: 0.5;
}
.addressFormUI {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
}
.addressList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.inputLabel {
  font-size: 13px;
  color: #333;
}
.inputWrapper input {
  background-color: #f4f5f6;
  padding: 10px;
  border: 1px solid #9d9a9b;
  width: 100%;
  border-radius: 0;
  color: #7a8081;
  line-height: 1;
}
.changeBtn {
  position: absolute;
  right: 8px;
  bottom: 4px;
  outline: none;
  border: 1px solid #eb2675;
  color: #eb2675;
  text-transform: uppercase;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 8px;
}
.modalFooter {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.secondaryBtn,
.primaryBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  text-transform: uppercase;
}
.secondaryBtn {
  background: transparent;
  color: #eb2675;
}
.primaryBtn {
  background: #eb2675;
  color: #fff;
  margin-left: 5px;
}
.addressFormUIHeader {
  font-size: 14px;
  font-weight: 600;
}
.addressFormUIDiv {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.addressListremoveMargin {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.pincodeAndLocality {
  display: flex;
  gap: 20px;
}
.addressFormHalfDiv {
  width: 50%;
}
.addressTypesWrapper {
  display: flex;
  flex-wrap: wrap;
}
.addressTypeIcon {
  padding: 4px 16px;
  border-radius: 20px;
  font-size: 12px;
  margin-right: 16px;
  cursor: pointer;
  text-transform: capitalize;
}
.addressFormUIisDefault {
  display: flex;
  align-items: center;
  gap: 5px;
}
.addressFormUIisDefault input[type="checkbox"] {
  accent-color: #eb2675;
}
.addressFormUIisDefault span {
  line-height: 13px;
}
.selectInput{
  padding: 10px;
  border: 1px solid #9d9a9b !important;
  height: 38px;
}
.selectInputOption {
  font-size: 14px !important;
}
@media (max-width: 767px) {
  .modalContent{
    width: 100%;
    height: 86vh;
    overflow: scroll;
  }
  .addressFormUIisDefault span {
    font-size: 13px;
  }
  .addressTypesWrapper {
    display: flex;
    flex-wrap: nowrap;
  }
}
