.section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #e827744a;
  }
  .loginBox {
    background-color: #fff;
    box-shadow: 0 0 30px rgb(0 0 0 / 5%);
    padding: 50px;
    width: 50%;
    margin: auto;
    max-width: 600px;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .formContainer h4 {
    clear: both;
    line-height: 1.4;
    font-weight: 500;
    color: #333;
    margin: 0;
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: left;
  }

  @media (max-width: 1199px) {
    .loginBox {
      width: 80%;
    }
  }
  @media (max-width: 767px) {
    .section {
      align-items: flex-start;
      min-height: 100%;
    }
    .loginBox {
      width: 100%;
      margin: 30px 0;
      padding: 30px;
      height: 100%;
    }
  }
  