.tabcontent {
  padding: 20px;
  border: 1px solid #ccc;
  width: 78%;
  border-left: none;
  border-bottom: none;
  border-right: none;
}
.editProfileSection {
  background: #ededed;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.imageAndProfileDetails {
  display: flex;
  align-items: center;
  gap: 30px;
}
.imageAndProfileDetails img {
  width: 100px;
  height: 100px;
}
.profileDetails {
  display: flex;
  flex-direction: column;
}
.profileDetails p {
  font-size: 15px;
}
.profileName {
  font-weight: 600;
  padding-bottom: 14px;
  text-transform: capitalize;
}
.editProfile {
  background-color: #eb2675 !important;
  border: 1px solid #eb2675 !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding: 15px 20px !important;
  font-size: 14px !important;
  line-height: normal !important;
  font-weight: 600 !important;
  width: max-content !important;
  text-transform: uppercase !important;
}
.editProfile:hover {
  background-color: transparent !important;
  color: #eb2675 !important;
}
.overviewList {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
  gap: 20px;
  justify-content: flex-start;
}
.list {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 16px;
  width: 197px;
  cursor: pointer;
}
.list:hover {
  background: #ededed;
}
.list svg {
  width: 35px;
  height: 35px;
}
.list:hover svg path {
  fill: #fff;
}
.list:hover {
  background: #eb2675;
  border: 1px solid #eb2675;
}
.list:hover p {
  color: #fff;
}
list:hover svg rect {
  stroke: #fff;
}
.overviewListHeading {
  padding-top: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.overviewListPera {
  font-size: 14px;
  color: #333;
}

@media (max-width: 992px) {
  .list {
    padding: 12px;
    width: 160px;
  }
}
@media (max-width: 767px) {
  .tabcontent {
    width: 100%;
    padding: 0;
  }
  .list {
    width: auto;
  }
}
@media (max-width: 550px) {
  .list {
    width: 100%;
  }
  .editProfileSection {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }
  .imageAndProfileDetails {
    align-items: flex-start;
  }
  .editProfile {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .imageAndProfileDetails svg {
    width: 60px;
    height: 60px;
  }
  .list svg {
    width: 30px;
    height: 30px;
  }
}
