.OTPWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.otpContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.contactTitle {
  margin: 0;
  margin-bottom: 10px;
}
.contactSubTitle {
  color: #7a7a7a;
  font-size: 14px;
}
.otpField {
  display: flex;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.otpField input {
  display: flex;
  width: inherit;
  height: 55px;
  font-size: 20px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #7a7a7a;
  font-weight: 600;
  color: #eb2675;
}
.otpField input:active {
  border: 1px solid #eb2675;
  color: #eb2675;
}
.otpField input:focus-visible {
  outline: 1px solid #eb2675;
}
.OTPFooter{
    color: #333;
    font-size: 14px;
}
.OTPFooter span, .resend {
    color: #eb2675;
    cursor: pointer;
}
