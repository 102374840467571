.Description-container {
  margin-top: 50px;
  max-width: 700px;
  position: relative;
}

.Description-container span {
  border-left: 2px solid#64ffda;
  font-size: 12px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 15px;
  display: inline-block;
  width: 2px;
  height: 42px;
  transition: transform 0.5s;
}

.Description-container span.index1-chosen {
  transform: translateY(0px);
}
.Description-container span.index2-chosen {
  transform: translateY(42px);
}
.Description-container span.index3-chosen {
  transform: translateY(84px);
}


.Description-styledTabList {
  padding: 0;
}
