.shopping_bag_content p, .payment-content p{
    margin: 0;
    padding: 0;
}
.shopping_bag_content, .address-section, .payment-content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 60%;
}
.verticalDivider {
    height: 100%;
    width: 1px;
    background: #ededed;
}
.shopping_bag_content .left, .address-section .address-left, .payment-content .left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding-right: 30px;
    width: 100%;
}
.shopping_bag_content .left .checkbox_with_text {
    display: flex;
    gap: 15px;
}
.shopping_bag_content input[type="checkbox"] {
    margin: 0;
    height: 16px;
    width: 16px;
}
.shopping_bag_content .left .checkbox_with_text p {
    font-size: 18px;
}
.shopping_bag_content .left .checkbox_with_text p {
    font-size: 18px;
    margin: 0;
    line-height: normal;
}
.shopping_bag_content input[type="checkbox"]:checked::before, .addressFormUIisDefault input[type="checkbox"]:checked::before, input[type="checkbox"].theme-checkbox:checked::before{
    background-color: #eb2675;
    border-color: #eb2675;
}
.shopping_bag_content .left .carts_sction {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px;
    position: relative;
}
.shopping_bag_content .left .carts_sction .cart {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-start;
    border-bottom: 1px solid #ededed;
    padding-bottom: 15px;
}
.shopping_bag_content .left .carts_sction .cart .left {
    position: relative;
    padding-right: 0px;
}
.shopping_bag_content .left .carts_sction .cart .left::after {
   display: none;
}
.shopping_bag_content .left .carts_sction .cart .left input[type="checkbox"] {
    position: absolute;
    top: 2%;
    left: 3%;
}
.shopping_bag_content .left .carts_sction .cart .left img {
    width: 150px;
    height: auto; 
    max-width: 100%;
    vertical-align: middle;
    max-width: 100% !important;
}
.shopping_bag_content .left .carts_sction .cart .right {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    width: 100%;
}
.shopping_bag_content .right , .address-section .address-right, .payment-content .right, .payment-content .right{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
}
.shopping_bag_content .left .carts_sction .cart .left {
    width: auto;
}
.shopping_bag_content .left .carts_sction .cart .right p.title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.shopping_bag_content .left .carts_sction .cart .right .select_section {
    display: flex;
    gap: 15px;
}
.shopping_bag_content .left .carts_sction .cart .right .select_section select {
    background: white;
    border: none;
    cursor: pointer;
    color: #000;
}
.shopping_bag_content .left .carts_sction .cart .right .select_section .select_options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 10px;
}
.review_page {
    background: #F5EBEF;
    padding: 5px 10px;
    border-radius: 0px !important;
    border: none !important;
}
.shopping_bag_content .left .carts_sction .cart .right .price_section {
    display: flex;
    align-items: center;
    gap: 15px;
}
.shopping_bag_content .left .carts_sction .cart .right .price_section p {
    font-size: 16px;
    line-height: normal;
}
.shopping_bag_content .left .carts_sction .cart .right .price_section p.actual_price {
    font-weight: 600;
}
.shopping_bag_content .left .carts_sction .cart .right .price_section p.original_price {
    text-decoration: line-through;
    color: grey;
}
.shopping_bag_content .left .carts_sction .cart .right .price_section p.offer_discount {
    color: #eb2675;
}
.shopping_bag_content .left .carts_sction .cart .right .return_section, .shopping_bag_content .left .carts_sction .cart .right .delivery_date_section {
    display: flex;
    align-items: center;
    gap: 9px;
}
.shopping_bag_content .left .carts_sction .cart .right .return_section i.back, .shopping_bag_content .left .carts_sction .cart .right .delivery_date_section i.check {
    font-size: 20px;
}
.shopping_bag_content .left .carts_sction .cart .right .return_section p, .shopping_bag_content .left .carts_sction .cart .right .delivery_date_section p {
    font-size: 14px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 999;
}
.close svg {
    width: 20px;
    height: 20px;
}

.shopping_bag_content .left .carts_sction .cart:last-child{
    border-bottom: 0;
    padding-bottom: 0;
}
/* .shopping_bag_content .left::after {
    content: "";
    height: 100%;
    width: 1px;
    background: #ededed;
    position: absolute;
    right: 0;
} */
.physicalItemsWrapper{
    border: 1px solid #ededed;
    border-radius: 6px;
}
.borderDiv{
    background-color:#ededed ;
    height: 1px;
    width: 93%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
}
@media (max-width: 991px) {
.shopping_bag_content, .address-section, .payment-content{
    width: 70%;
}
}

@media (max-width: 767px) {
    .shopping_bag_content, .address-section, .payment-content{
        width: 100%;
    }
    .shopping_bag_content .left, .address-section .address-left, .payment-content .left {
        padding-right: 0px;
    }
    .shopping_bag_content .left::after {
        display: none;
    }
}

@media (max-width: 550px) {
    .shopping_bag_content .left .carts_sction .cart .left img {
        width: 220px;
    }
    .shopping_bag_content .left .carts_sction .cart {
        flex-direction: column;
        gap: 25px;
        position: relative;
    }
    .close {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        z-index: 999;
    }
}