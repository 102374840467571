.container {
  width: 90%;
  margin: auto;
  padding: 0px 10px 0px;
}
.breadCrumb {
  padding: 10px 0;
  display: flex;
  gap: 8px;
}
.wishlistDisabled{
  background-color: #FEC196 !important;
  color: #000 !important;
  border: 0 !important;
}
.breadCrumb p {
  display: inline-block;
  color: #eb2675;
}
.breadCrumbP {
  cursor: pointer;
  font-weight: 400 !important;
}
.breadCrumbName {
  color: #333 !important;
}
.breadCrumb:after,
.breadCrumb:hover {
  color: #5a5254;
}
.breadCrumb p:not(:last-child):after {
  content: ">";
  display: inline-block;
  margin: 0 3px 0 6px;
}

.breadCrumb p {
  font-weight: 600;
}
.productDetailsWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}
.productDetailsTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.productDetailsTitle {
  font-size: 24px;
  color: #333;
  display: flex;
  justify-content: space-between;
  line-height: 34px;
  font-weight: 400;
}
.productDetailsSku {
  font-size: 14px;
  color: #333;
  font-weight: 400;
}
.productDetails ul,
ul li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.instock {
  color: #fff;
  background-color: #4cc45a;
  padding: 6px;
  margin: 15px 0;
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
}
.instock p,
.outOfStock p {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.outOfStock {
  color: #fff;
  background-color: red;
  padding: 6px;
  margin: 15px 0;
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
}
.price,
.calculatedPrice {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 20px;
}
.price {
  padding-left: 9px;
  color: #333;
}
.calculatedPrice {
  color: #eb2676;
  padding-bottom: 10px;
}
.startRating {
  color: #eb2676;
  font-size: 14px;
}
.description {
  font-size: 14px;
  font-family: sans-serif;
}
.productDetails ul h5 {
  font-size: 16px;
}
.size,
.color,
.Qty,
.addCartWrapper {
  padding-top: 20px;
}
.size p {
  font-size: 16px;
  font-weight: 500;
  /* padding-bottom: 8px; */
}
.sizeDataWrapper {
  display: flex;
  gap: 5px;
}
.sizeBox {
  text-align: center;
  padding: 6px 0;
  font-size: 12px !important;
  width: 50px;
}

.colorSelect {
  text-align: left;
  width: 170px;
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  border-radius: 0;
  color: #7a8081;
  padding: 0.67em 0.5em;
  border: 1px solid #9d9a9b;
  line-height: 1;
  text-transform: none;
}
.Active {
  background-color: #eb2676 !important;
  color: #fff !important;
  border: 1px solid #eb2676 !important;
}
.QtyBtnWrapper {
  display: flex;
  gap: 5px;
}
.Qty button {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 1;
  padding: 0.47em 0;
  font-size: 20px;
  background-color: #5a5254;
  color: #fff;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  border: none;
}
.Qty button:hover {
  background-color: #eb2675;
}
.Qty p {
  width: 48px;
  height: 38px;
  text-align: center;
  border: 1px solid #9d9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7a8081;
}
.addCartWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}
.addCart {
  padding: 0.95em 2.4em;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  border-radius: 0;
  background-color: #eb2675;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #eb2675;
}
.addCart:hover {
  color: #eb2675;
  background-color: #fff;
}
.wishList {
  padding: 0.95em 2.4em;
  border: 1px solid #eb2675;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #eb2675;
  border-radius: 0;
  background-color: transparent;
  font-size: 15px;
  font-weight: bold;
}

.wishList:hover {
  color: #fff;
  background-color: #eb2675;
  border: 1px solid #eb2675;
}
.delivery_options {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.label p {
  font-size: 16px;
  color: #5a5254;
  padding-bottom: 5px;
  font-weight: 400;
}
.single_product div.product .variations_form .label {
  font-weight: bold;
  text-align: left;
  display: block;
  padding: 0;
  margin-bottom: 5px;
}
.variations_form .label {
  font-weight: bold;
  text-align: left;
  display: block;
  padding: 0;
  margin-bottom: 5px;
}
.variations_form .label label {
  font-size: 16px;
}
.swatches{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.delivery_form {
  position: relative;
  width: 250px;
  padding-bottom: 10px;
  display: flex;
}
.delivery_form input {
  background-color: #fff;
  border-radius: 0;
  color: #7a8081;
  padding: 0.67em 0.5em;
  border: 1px solid #9d9a9b;
  width: 100%;
  line-height: 1;
}

.pincode_code {
  border-right: 0px !important;
  outline: none;
}

.pincode_button {
  background: transparent !important;
  border: 1px solid #9d9a9b !important;
  outline: none;
  padding: 0;
  margin: 0;
  color: #eb2675 !important;
  font-weight: 600;
  max-width: 100px;
  border-left: 0px !important;
  text-transform: uppercase;
  text-align: end;
}
.pincode_subpara {
  font-size: 14px;
  color: #333;
  font-weight: 400;
}
.delivery_sub_text {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.delivery_sub_text p {
  margin: 0 !important;
  margin-bottom: 0px !important;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}
.ratingbarWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
}
.ratingbarTitle {
  font-size: 16px;
  color: #5a5254;
  font-weight: 400;
}
.ratingbarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  width: 100%;
}
.ratings {
  min-width: 130px;
}
.ratings h1 {
  font-size: 2.5rem;
  color: #333;
  font-weight: lighter;
}
.ratings span {
  padding-left: 12px;
}
.ratings p {
  font-size: 14px;
  font-weight: 400;
}
.ratings svg {
  font-size: 2rem;
  vertical-align: baseline !important;
  color: #eb2675;
}
.ratingBars {
  width: 100%;
}
.middleLine {
  height: 101px;
  width: 1px;
  background-color: #9d9a9b;
}

.ratingBars .barsStars {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.75rem;
  margin-bottom: 0.4rem;
  white-space: nowrap;
}
.ratingBars .barsStars svg {
  font-size: 10px;
  color: #9d9a9b;
}
.ratingBars .barsStars .bar {
  width: 70%;
  height: 2px;
}
.what_customer_said {
  padding-top: 30px;
}
.what_customer_said p {
  font-size: 16px;
}
.customerSaidBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 0.75rem;
}
.customerSaidBarContainer .bar {
  width: 200px;
  height: 2px;
}
.productShareSocialMedia {
  padding-left: 0px;
  padding-top: 20px;
}

.productShareSocialMedia li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
}

.productShareSocialMedia li button {
  padding: 10px;
  border: 1px solid;
  font-size: 13px;
  display: block;
  width: 36px;
  padding: 0.51em 0;
  color: #979494;
  text-align: center;
  border: 1px solid #979494;
  line-height: 1;
  background-color: #fff;
  transition: 0.5s ease;
}

.productShareSocialMedia li button:hover {
  color: #fff;
  border: 1px solid #eb2675;
  background-color: #eb2675;
}
.productShareSocialMedia + p span {
  color: #eb2675;
}
.productShareSocialMediaWrapper p {
  font-size: 14px;
  font-weight: 400;
}
.productShareSocialMediaWrapper p a {
  background: #ededed;
  color: #000 !important;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.imageSliderContainer{
  width: 58.5%;
  padding-bottom: 15px;
}
.productDetailsContainer{
  width: 39%;
}
.starIcon{
  font-size: 16px;
}
.row{
  padding: 20px 10px;
}

@media (max-width: 1024px) {
  .productDetailsTitle {
    font-size: 20px;
    font-weight: 400;
  }
  .price,
  .calculatedPrice {
    font-size: 15px;
  }
  .Qty button {
    width: 30px;
    height: 30px;
    padding: 4px 0;
  }
  .Qty p {
    width: 40px;
    height: 30px;
  }
  .addCart,
  .wishList {
    padding:13px 14px;
  }
  .imageSliderContainer{
    width: 46%;
  }
  .productDetailsContainer{
    width: 52%;
  }
  .ratingbarContainer{
    gap: 35px;
  }
}

@media (max-width: 767px) {
  .productDetailsWrapper {
    flex-direction: column;
  }
  .breadCrumb p {
    font-size: 13px;
  }
  .breadCrumb {
    padding: 0px;
  }
  .productDetailsTitleWrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .addCart,
  .wishList {
    font-size: 13px;
    padding: 9px 13px;
  }
  .imageSliderContainer,.productDetailsContainer{
    width: 100%;
  }
  .starIcon{
    font-size: 13px;
  }
  .row{
    padding: 0px;
  }
}
@media (max-width: 600px) {
  .ratingbarContainer {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
