.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  overflow: hidden;
  margin: 100px 0px;
}
.titleIconWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.wrapperTitle {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.container {
  height: auto;
  padding: 28px 32px;
  gap: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex !important;
  align-items: flex-start;
  flex: 1 0 0;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}
.ratingIcons {
  display: flex;
  align-items: flex-start;
  gap: 2px;
}
.nameWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.name {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.comment {
  color: #1c1c1c;
  font-size: 16px;
  text-align: left;
}
.icon{
    cursor: pointer;
}
@media (max-width: 1440px) {
  .wrapper {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .wrapperTitle {
    font-size: 30px;
  }
  .container {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .container {
    padding: 16px;
  }
}
@media (max-width: 767px) {
  .titleIconWrapper {
    justify-content: center;
  }
  .wrapperTitle {
    font-size: 26px;
  }
}
@media (max-width: 450px) {
  .wrapperTitle {
    font-size: 22px;
  }
}
