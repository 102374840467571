.login_form p.login_username {
  margin: 0;
  padding-bottom: 25px;
}

.inputGroup label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.inputGroup input {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  border-radius: 3px;
  padding: 0.67em 0.5em;
  border: 1px solid #9d9a9b;
  width: 100%;
  line-height: 1;
  outline: none;
}
.isPasswordVisible {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.isPasswordVisible svg {
  width: 22px;
  height: 22px;
}
.countrySelector {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  border-radius: 0;
  color: #7a8081;
  padding: 0.67em 0.5em;
  border: 1px solid #9d9a9b;
  width: 100%;
  line-height: 1;
}
.Mobile {
  display: flex;
  gap: 20px;
}
.Mobile button {
  border: 0;
}
.submitButton {
  background: #1a1a1a;
  border: 0;
  border-radius: 4px;
  color: #fff;
  letter-spacing: 0.046875em;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
  width: 100%;
}
.submitButton:hover {
  background-color: #eb2675;
}
.inputGroup p span {
  font-weight: bold;
  color: #eb2675;
  cursor: pointer;
}
.errorMsg {
  padding-top: 10px;
  color: red !important;
}
.inputGroup p {
  font-size: 14px;
  color: #333;
}
.line {
  margin: 0;
  height: 1px;
}
.line::after {
  content: "";
  position: relative;
  background-color: #e7e7e7;
  width: 296px;
  height: 1px;
  display: block;
  margin: 0 auto;
}
.newUpvanText {
  font-size: 16px;
  text-align: center;
}
.loginBtn {
  padding: 0.95em 2.4em;
  background: transparent;
  border: 1px solid #eb2675;
  color: #eb2675;
  border-radius: 0;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: max-content;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  border-radius: 0;
  line-height: 1;
  font-size: 14px;
  text-transform: uppercase;
}
.loginBtn:hover {
  background-color: #eb2675;
  color: #fff !important;
}
.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: left;
}
.privacyText {
  text-align: center;
}
.subTitle{
    font-size: 14px;
    margin-top: -10px;
    font-weight: 400;
}
