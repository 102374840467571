
.paymentContent {
    width: 60%;
}
.paymentContent .left section.py5.header .container.py-4 {
    width: -webkit-fill-available;
    margin: 0;
    padding: 0;
}
.paymentContent .left section.py5.header .container.py-4 .colMd4 {
    padding: 0;
}
.paymentContent .nav a {
    padding: 20px 20px;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.paymentContent .left .row div#v-pills-tabContent {
    background: #fff;
    min-height: 61vh;
}
.paymentContent .left section.py5Header .py4 .colMd4 .nav span.fontWeightBold.small.textUppercase {
    font-size: 14px;
    text-transform: uppercase;
}
.paymentContent .nav a svg {
    height: 24px;
    width: 22px;
}
.paymentContent .left {
    border: 1px solid #ededed;
    padding-right: 0;
}
.paymentContent .left .row {
    background: #ededed;
}
/* .paymentContent .nav a.active {
    background: white;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid #eb2675;
    transition: linear .1s;
}
.paymentContent .nav a.active span{
    color: #eb2675;
}
.paymentContent .nav a span {
    color: #000;
}
.paymentContent .nav a:hover{
    color: inherit;
}
.paymentContent .nav a.active svg path {
    stroke: #eb2675;
    fill: #eb2675;
}
.paymentContent .nav a.active svg rect {
    stroke: #eb2675;
}
.paymentContent .nav a.active span {
    text-decoration: none;
}
.paymentContent .nav a.active:focus {
    outline: 0;
} */
.colMd8 {
    padding: 0;
    background-color: white;
}
.colMd4 {
    padding: 0;
}
.paymentContent .active {
    background: white;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid #eb2675;
    transition: linear .1s;
}
.paymentContent .active span{
    color: #eb2675;
}
.paymentContent  span {
    color: #000;
}
.paymentContent :hover{
    color: inherit;
}
.paymentContent .active svg path {
    stroke: #eb2675;
    fill: #eb2675;
}
.paymentContent .active svg rect {
    stroke: #eb2675;
}
.paymentContent .active span {
    text-decoration: none;
}
.paymentContent .active:focus {
    outline: 0;
}

.paymentContent .left .col-md-8 {
    padding: 0;
}
.paymentContent .left .tab-pane {
    padding: 0;
}
.recommendedtab, .codtab, .upiapptab, .creditdebitcardtab, .walltestab , .paylatertab, .emitab, .netbankingtab {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 25px;
    position: relative;
}
.paymentContent .left .tab-pane i.pe-7s-angle-down{
    display: none;
}
.paymentContent .left .tabContent p.heading {
    font-size: 16px;
    font-weight: 600;
}
.paymentContent .left .tabContent ul.listing {
    list-style: none;
    padding: 0;
    margin: 0;
}
.paymentContent .radioWithText {
    display: flex;
    align-items: center;
    gap: 10px;
}
.paymentContent .radioWithTextWithCashIcon {
    display: flex;
    justify-content: space-between;
    font-weight: bolder;
}
.paymentContent .icon svg {
    width: 22px;
    height: 22px;
}
.listingInnerDiv button.defaultBtnNextStep {
    margin: 0;
}
.paymentContent .listingInnerDiv {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.extraCashForCod {
    background: #ededed;
    border-radius: 4px;
    padding: 10px;
}
.paymentContent .left .tabContent ul.listing .listingInnerDiv .inputCode {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.paymentContent .left .tabContent ul.listing .listingInnerDiv .inputCode label {
    font-size: 13px;
}
.paymentContent .left .tabContent ul.listing .radioWithText p.text {
    font-weight: 600;
    font-size: 14px;
}
img.upi-app-image {
    border: 1px solid #ededed;
    border-radius: 100px;
    padding: 5px 5px;
    width: 50px;
}
.tabContainer {
    display: flex;
  }
  .tab {
    border: 1px solid #ccc;
    width: 22%;
    border-left: none;
    border-bottom: none;
    border-right: 1px solid #ccc;
    padding-right: 20px;
  }
  .tablinks {
    display: block;
    width: 100%;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    border-radius: 0;
    padding: 20px;
    border: none;
    background: transparent;
    line-height: normal;
    text-transform: uppercase;
    border-bottom: 1px solid #dbdbdb;
  }
  .tablinks:hover {
    color: #eb2675 !important;
    font-weight: 600;
  }
  
  @media (max-width: 1199px) {
    .container {
      width: 970px;
    }
    .tablinks {
      padding: 20px 0;
    }
  }
  
  @media (max-width: 992px) {
    .container {
      width: 750px;
    }
    .paymentContent .left section.py5Header .py4 .colMd4 .nav span.fontWeightBold.small.textUppercase {
        font-size: 10px;
    }
  }
  @media (max-width: 767px) {
    .container {
      width: 100%;
      padding: 20px 19px 100px;
    }
    .paymentContent{
        width: 100%;
    }
    .paymentContent  .fontWeightBold {
        margin-left: 1rem;
    }

  }