.paginationContainer{
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.paginationButtons{
    text-align: center;
    line-height: 1;
    padding: 0.72em 1em;
    border: 1px solid #333;
    color: #333;
    background-color: #fff;
    margin: 2px;
    /* transition: 0.5s ease; */
}
.paginationButtons:hover{
    text-align: center;
    line-height: 1;
    padding: 0.72em 1em;
    border: 1px solid #333;
    color: #fff;
    background-color: #eb2675;
    margin: 2px;
}

.buttonPanel{
    display: flex;
    justify-content: center;
    max-width: 90%;
    width: 100%;
}
.pageInfo{
  text-align: left;
  width: 100%;
  font-size: 14px;
  padding-bottom: 10px;
}
.divider{
  width: 100%;
  height: 1px;
  background-color: #ddd;
}
.active{
    width: 42px;
    text-align: center;
    line-height: 1;
    padding: 0.72em 1em;
    color: #fff;
    background-color: #eb2675;
    border: 1px solid #333;
}

.buttonPanel {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    /* border-top: 1px solid #ddd; */
    /* padding-top: 50px !important; */
  }

  .buttonPanel .prev {
    margin-right: 40px;
    text-align: center;
    line-height: 1;
    padding: 0.72em 1em;
    border: 1px solid #ccc;
    color: #313131;
    background-color: #fff;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 8px;
  }
  .buttonPanel .next {
    margin-left: 40px;
    text-align: center;
    line-height: 1;
    padding: 0.72em 1em;
    border: 1px solid #ccc;
    color: #313131;
    background-color: #fff;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 8px;
  }
  .buttonPanel .next:hover svg ,.buttonPanel .prev:hover svg{
    stroke:#eb2675;
  }
  .buttonPanel .next:hover,.buttonPanel .prev:hover {
    background: transparent !important;
    color: #eb2674 !important;
    border: 1px solid #eb2674;
  }
  .buttonPanel a {
    padding: 10px 10px !important;
  }
  .buttonPanel a.prev.buttonPanel:hover, .buttonPanel a.next.buttonPanel:hover{
    background: transparent !important;
    color: #eb2674 !important;
  }
  
  .pagination .buttonPanel,
  .woocommerce-pagination .buttonPanel {
    list-style: none;
    display: inline-block;
  }
  
  .pagination .buttonPanel .buttonPanel,
  .woocommerce-pagination .buttonPanel .buttonPanel,
  .pagination li a {
    width: 42px;
    text-align: center;
    line-height: 1;
    padding: 0.72em 0;
    border: 1px solid #5a5254;
    display: block;
    color: #5a5254;
  }
  
  .pagination .buttonPanel a.buttonPanel:hover,
  .woocommerce-pagination .buttonPanel a.buttonPanel:hover,
  .pagination .buttonPanel .buttonPanel.current,
  .woocommerce-pagination .buttonPanel .buttonPanel.current,
  .pagination a:hover {
    color: #fff;
  }
  @media only screen and (max-width: 991px) {
    .paginationContainer{
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 767px) {
    .buttonPanel .prev,.buttonPanel .next {
      margin: 0px;
    }
  }