.contactPage {
}
.main {
  display: block;
}
.primaryPadding {
  padding: 100px 0;
}
.contactTitle {
  margin-bottom: 10px;
}
.loginTop h4.contactTitle {
  margin: 0;
}

.contactTitle i {
  display: block;
  font-size: 72px;
  margin-bottom: 15px;
}

.contactPage .box {
  padding: 0 25%;
}

.contactPage .box span {
  display: block;
}

.contactPage button[type="submit"] {
  margin-top: 5px;
}

.contactPage textarea {
  min-height: 199px;
}

.mapWrap iframe {
  width: 100%;
  height: 415px;
  display: block;
}
ul.socialIcons {
  margin: 0;
  padding: 0;
}

ul.socialIcons li {
  display: inline-block;
  margin-right: 2px;
}

ul.socialIcons li a {
  display: block;
  width: 36px;
  padding: 0.51em 0;
  color: #979494;
  text-align: center;
  border: 1px solid #979494;
  line-height: 1;
}

ul.socialIcons li a:hover {
  color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  border-color: transparent;
}

footer ul.socialIcons li a {
  line-height: 1.4;
}
/* ::-webkit-input-placeholder {
  color: #a9a9a9;
}

:-moz-placeholder {
  color: #a9a9a9;
}

::-moz-placeholder {
  color: #a9a9a9;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #a9a9a9;
}

:focus::-webkit-input-placeholder,
.subscribe-form input:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus:-moz-placeholder,
.subscribe-form input:focus:-moz-placeholder {
  color: transparent;
}

:focus::-moz-placeholder,
.subscribe-form input:focus::-moz-placeholder {
  color: transparent;
  opacity: 1;
}

:focus:-ms-input-placeholder,
.subscribe-form input:focus:-ms-input-placeholder {
  color: transparent;
} */

.contactPage button,
.contactPage button[disabled]:hover,
.contactPage button[disabled]:focus,
.contactPage input[type="button"],
.contactPage input[type="button"][disabled]:hover,
.contactPage input[type="button"][disabled]:focus,
.contactPage input[type="reset"],
.contactPage input[type="reset"][disabled]:hover,
.contactPage input[type="reset"][disabled]:focus,
.contactPage input[type="submit"],
.contactPage input[type="submit"][disabled]:hover,
.contactPage input[type="submit"][disabled]:focus {
  background: #1a1a1a;
  border: 0;
  border-radius: 4px;
  color: #7a8081;
  letter-spacing: 0.046875em;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
}

.contactPage button:hover,
.contactPage button:focus,
.contactPage input[type="button"]:hover,
.contactPage input[type="button"]:focus,
.contactPage input[type="reset"]:hover,
.contactPage input[type="reset"]:focus,
.contactPage input[type="submit"]:hover,
.contactPage input[type="submit"]:focus {
  background: #007acc;
}

.contactPage button:focus,
.contactPage input[type="button"]:focus,
.contactPage input[type="reset"]:focus,
.contactPage input[type="submit"]:focus {
  outline: thin dotted;
  outline-offset: -4px;
}

.contactPage input[type="date"],
.contactPage input[type="time"],
.contactPage input[type="datetime-local"],
.contactPage input[type="week"],
.contactPage input[type="month"],
.contactPage input[type="text"],
.contactPage input[type="email"],
.contactPage input[type="url"],
.contactPage input[type="password"],
.contactPage input[type="search"],
.contactPage input[type="tel"],
.contactPage input[type="number"],
.contactPage textarea,
.contactPage button[type="submit"],
.contactPage select {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  border-radius: 0;
  color: #7a8081;
  padding: 0.67em 0.5em;
  border: 1px solid #9d9a9b;
  width: 100%;
  line-height: 1;
}

.contactPage textarea {
  min-height: 150px;
  padding: 10px;
}

.contactPage input[type="date"]:focus,
.contactPage input[type="time"]:focus,
.contactPage input[type="datetime-local"]:focus,
.contactPage input[type="week"]:focus,
.contactPage input[type="month"]:focus,
.contactPage input[type="text"]:focus,
.contactPage input[type="email"]:focus,
.contactPage input[type="url"]:focus,
.contactPage input[type="password"]:focus,
.contactPage input[type="search"]:focus,
.contactPage input[type="tel"]:focus,
.contactPage input[type="number"]:focus,
.contactPage.textarea:focus {
  border-color: #d2d8da;
  color: #1a1a1a;
  outline: 0;
}
.mb50 {
  margin-bottom: 50px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb90 {
  margin-bottom: 90px;
}
.contactPage label {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
}

.contactPage label span {
  color: #5a5254;
}
.contactPage label span:hover {
  color: #eb2673;
}
.contactPage button[type="submit"] {
  background-color: #5a5254;
  color: #fff;
}
.contactPage button[type="submit"]:hover {
  background-color: #eb2673;
}
