.container {
  width: 1170px;
  margin: auto;
  padding: 20px 15px 100px;
  color: #333;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 0 0 0;
}
.heading {
  margin: 0;
  font-size: 30px;
  line-height: normal;
  text-align: center;
}
.pageContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.content span {
  font-size: 16px;
  font-weight: 700;
}
.content p,
.content ul li {
  font-size: 15px;
  list-style: disc;
  font-weight: 400;
}
.content ul {
  margin-bottom: 0;
  padding-top: 10px;
  padding-left: 18px;
}
@media (max-width: 1199px) {
  .container {
    width: 970px;
  }
}
@media (max-width: 992px) {
  .container {
    width: 750px;
  }
}
@media (max-width: 767px) {
  .container {
    width: 100%;
    padding: 20px 19px 100px;
  }
  .wrapper {
    padding: 0px;
  }
}
