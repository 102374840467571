/* ZoomableImage.css */
figure.zoom {
    position: relative;
    
    overflow: hidden;
    cursor: zoom-in;
    display: inline-block; /* Ensures the figure wraps tightly around the content */
  }
  
  figure.zoom img {
    display: block;
    width: 100%;
    height: 76vh
  }
  
  figure.zoom img:hover {
    opacity: 0; /* Image fades out on hover */
  }

  @media only screen and (max-width: 600px) {
    figure.zoom img {
    height: auto;
    }
  }