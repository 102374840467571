.tabcontent {
  padding: 20px;
  border: 1px solid #ccc;
  width: 78%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  color: #333;
}
.profileDetailsSection {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.profileImageWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.profileImageWrapper img {
  height: 80px;
  width: 80px;
}
.profileImageWrapper p {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.profileOtherDetails {
  display: flex;
  flex-direction: column;
}
.detailsWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid #ccc;
  padding: 14px 0;
  width: max-content;
}
.detailsWrapper p {
  font-size: 16px;
  width: 200px;
}
.editBtn {
  background-color: #eb2675 !important;
  border: 1px solid #eb2675 !important;
  color: #fff !important;
  border-radius: 0;
  padding: 15px 20px !important;
  font-size: 14px !important;
  line-height: normal;
  font-weight: 600;
  width: max-content !important;
  text-transform: uppercase !important;
}
.editBtn:hover {
  background-color: transparent;
  color: #eb2675;
}
@media (max-width: 767px) {
  .profileDetailsSection{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tabcontent {
    width: 100%;
    padding: 0;
  }
  .modalFooter {
    text-align: center;
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.primaryBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  text-transform: uppercase;
}
}
@media (max-width: 550px) {
  .profileImageWrapper svg {
    width: 60px;
    height: 60px;
  }
  .editBtn {
    padding: 10px !important;
    font-size: 12px !important;
  }
  .modalFooter {
    text-align: center;
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.primaryBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px;
  border-radius: 4px;
  cursor: pointer;

  line-height: 1;
  text-transform: uppercase;
}
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
}
.modalContent {
  border-radius: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 600px;
}
.modalHeader {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.closeBtn {
  width: 30px;
  height: 30px;
  font-size: 18px;
  opacity: 1;
  border-radius: 50%;
  background-color: #eb2675;
  border: 1px solid #eb2675;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  text-align: center;
  font-weight: 600;
  color: #fff;
  bottom: 30px;
  top: 0;
  right: 0;
  margin: auto 0 !important;
}
.closeBtn:hover {
  opacity: 0.5;
}
.addressFormUI {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
}
.addressList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.inputLabel {
  font-size: 13px;
  color: #333;
}
.inputWrapper input {
  background-color: #f4f5f6;
  padding: 10px;
  border: 1px solid #9d9a9b;
  width: 100%;
  border-radius: 0;
  color: #7a8081;
  line-height: 1;
}
.changeBtn {
  position: absolute;
  right: 0;
  transform: translate(-50%, -50%);
  top: 58%;
  outline: none;
  border: 1px solid #eb2675;
  color: #eb2675;
  text-transform: uppercase;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 8px;
}
.modalFooter {
  padding: 15px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
}
.secondaryBtn,
.primaryBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  text-transform: uppercase;
}
.secondaryBtn {
  background: transparent;
  color: #eb2675;
}
.primaryBtn {
  background: #eb2675;
  color: #fff;
}
@media (max-width: 767px) {
  .modalContent {
    width: 100%;
    height: auto !important;
    overflow: scroll;
  }
  .primaryBtn {
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #eb2675;
    padding: 8px 16px 7px;
    border-radius: 4px;
    cursor: pointer;
    background: #eb2675 !important;
    line-height: 1;
    text-transform: uppercase;
    color: #fff !important;
    text-align: center !important;
  }
  .secondaryBtn {
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #eb2675;
    padding: 8px 16px 7px;
    border-radius: 4px;
    cursor: pointer;
    line-height: 1;
    text-align: center !important;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 1040px) {
  .modalContent {
    width: 100%;
    height: 86vh;
    overflow: scroll;
  }
}