.tabcontent {
  padding: 20px;
  border: 1px solid #ccc;
  width: 78%;
  min-height: 70vh;
  border-left: none;
  border-bottom: none;
  border-right: none;
  color: #333;
}
.addressesHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 500px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.heading {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
  color: #333;
}
.para {
  font-size: 16px;
  text-align: center;
  color: #333;
  font-weight: 400;
}
.addressSection {
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: flex-start;
  justify-content: space-between;
}
.addressWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addressTitle {
  float: left;
  font-weight: 700;
  color: #333333;
  font-size: 18px;
}
.addNewAddressBtn {
  font-size: 12px !important;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px !important;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  color: #eb2675 !important;
  width: max-content !important;
}
.addressListWrapper {
  vertical-align: top;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.addressListInnerWrapper {
  padding: 20px 20px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  border: 1px solid #eaeaea;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
}
.addressListInnerWrapper:last-child {
  border-bottom: 0;
}
.addressListInnerWrapper input[type="radio"] {
  margin: 0;
  accent-color: #eb2675;
  cursor: pointer;
  margin-top: 5px;
}
.addressDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.addressDetailsHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.addressDetailsName {
  font-weight: 700;
  font-size: 14px;
  color: #282c3f;
  text-transform: capitalize;
  word-break: break-all;
}
.addressDetailsaddressType {
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 20px;
  color: #03a685;
  font-weight: 700;
  border: 1px solid #03a685;
  line-height: 12px;
  text-transform: uppercase;
}
.defaultAddress {
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 20px;
  color: #fff;
  background: #eb2675;
  font-weight: 700;
  border: 1px solid #eb2675;
  line-height: 12px;
  text-transform: uppercase;
}
.addressDetailsAddress {
  word-break: break-all;
  font-weight: 400;
  text-transform: capitalize;
}
.addressDetailsMobile,
.deliveryOptions ul li {
  color: #333;
  font-size: 14px;
}
.deliveryOptions ul li {
  list-style: disc;
}
.deliveryOptions ul {
  margin-bottom: 0;
  padding-left: 1rem;
}
.addressDetailsBtns {
  display: flex;
  gap: 16px;
}
.addressDetailsBtn {
  font-size: 12px !important;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px !important;
  background-color: transparent;
  color: #eb2675 !important;
  cursor: pointer;
  width: max-content !important;
  line-height: 1;
  border-radius: 4px;
  text-transform: uppercase !important;
}

.addressDetailsBtn:focus {
  background: transparent;
  outline: 0;
}
.addressDetailsBtn:hover {
  background-color: transparent;
}

@media (max-width: 767px) {
  .tabcontent{
    width: 100%;
    padding: 20px 0 0;
  }
  .addressesHeader {
    width: 100%;
  }
  .addressDetailsMobile {
    font-size: 13px;
  }
}
@media (max-width: 550px) {
  .titleContainer{
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}