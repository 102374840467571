.homepageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  margin-bottom: 100px;
}
.homepageContainer::before, .homepageContainer::after {
  display: none;
}
.main {
  display: block;
}
/* .primaryPadding {
  padding: 100px 0;
} */
.homepage p{
  margin: 0;
}
/* .homeSlide img {
 width: -webkit-fill-available;
 height: 600px;
} */
/* main.main.primaryPadding.homepage {
  padding: 79px 0 0 0;
} */
.homePageSlider {
  width: 100%;
  height: 600px;
  position: relative;
}
.homePageSlider .carousel-inner {
  height: 100% !important;
}
.carouselItem {
  height: 100% !important;
}
:global .carousel-inner {
  height: 100% !important;
}
.homePageSlider img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 1s;
}
:global .carousel-indicators button {
  cursor: pointer;
  height: 15px !important;
  width: 15px !important;
  margin: 10px 2px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  padding: 20px 0 0;
  box-sizing: border-box !important;
  display: inline-block;
  border: 2px solid #ccc !important;
}
:global .carousel-indicators button.active {
  border: 6px solid #EB2675 !important;
  background-color: #fff;
}
.productWidth .bx-wrapper {
  width: 78%;
  max-width: initial !important;
}
.homePageProductlisting {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;
}
.homePageProductlisting li.product .productInnerSection {
  padding: 0 !important;
}
.product{
  padding: 0px 10px 50px;
}
.homePageProductlisting::before, .homePageProductlisting::after{
  display: none;
}
section.specificCollection {
  display: flex;
  align-items: center;
  gap: 60px;
}
section.specificCollection .content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.content p {
  font-size: 20px;
  padding-right: 100px;
}
section.specificCollection img.specificCollectionImage {
  width: -webkit-fill-available;
}
.specificCollectionImageWrapper{
  width: -webkit-fill-available;
}
.reverse-section {
  flex-direction: row-reverse;
}
section.fiveProdcutList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  overflow: hidden;
  width: 100%;
  position: relative;
}
section.fiveProdcutList .btn2, section.fashionNews .btn2 {
  position: absolute;
  top: 0;
  right: 0;
}
.heading{
  font-weight: 400;
}
.section.fiveProdcutList h2{
  font-weight: 600;
}
section.fiveProdcutList h2, section.fashionNews h2, section.testionial h2, section.specificCollection h2 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
section.fiveProdcutList .productlist {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}
section.fiveProdcutList .productlist .productlistPoints {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.specificCollectionImage {
  width: 100% !important;
}
section.fiveProdcutList .productlist .productlistPoints .productlistpointsImage {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
section.fiveProdcutList .productlist .productlistPoints .productlistContent {
  display: flex;
  padding: 25px 25px 0 25px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
section.fiveProdcutList .productlist .productlistPoints .productlistContent p.title {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
section.fiveProdcutList .productlist .productlistPoints .productlistContent .realpriceActualPrice {
  display: flex;
  align-items: center;
  gap: 10px;
}
section.fiveProdcutList .productlist .productlistPoints .productlistContent .realpriceActualPrice p {
  font-size: 18px;
  color: #BDBDBD;
  font-weight: 700;
  line-height: normal;
}
section.fiveProdcutList .productlist .productlistPoints .productlistContent .realpriceActualPrice p.realprice {
  color: #EB2675;
}
section.fiveProdcutList .productlist .productlistPoints .productlistContent .ratingIcons {
  display: flex;
  align-items: center;
  gap: 4px;
}
svg.not-fill path {
  fill: transparent;
  stroke: #EB2675;
}
.productlistpointsImage img.productlistpointsImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
section.catgeoriesBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: -webkit-fill-available;
  padding-top: 60px;
}
section.catgeoriesBlock .blockSection {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: -webkit-fill-available;
  overflow-x: hidden;
}
section.catgeoriesBlock .blockSection .large, section.catgeoriesBlock .blockSection .small {
  position: relative;
  height: 400px;
}
.categoryDetailsSection{
  max-width: fit-content;
}
.productName{
  font-size: 16px;
  padding: 20px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price a{
  padding: 5px 2px 0px;
  font-size: 18px !important;
}
section.catgeoriesBlock .blockSection .categoryDetailsSection {
  display: inline-flex;
  padding: 15px 20px;
  flex-direction: column ;
  justify-content: center;
  align-items: flex-start ;
  gap: 20px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  border: 1px solid rgba(249, 250, 251, 0.05);
  background: rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(20px);
}
section.catgeoriesBlock .blockSection .categoryDetailsSection .categoryDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #fff;
  line-height: normal;
}
.heading {
  font-size: 30px;
}
section.catgeoriesBlock .blockSection .categoryDetailsSection .categoryDetails p.offer {
  font-size: 16px;
}
section.catgeoriesBlock .blockSection .categoryDetailsSection .categoryDetails p.price {
  font-size: 20px;
  font-weight: 700;
  padding-top: 8px;
}
section.catgeoriesBlock .blockSection .categoryDetailsSection button.btn1 {
  width: -webkit-fill-available;
  text-transform: uppercase;
}
section.catgeoriesBlock .blockSection .categoryDetailsSection button.btn1:hover {
  border: 1px solid #fff !important;
  color: #fff !important;
}
.btn1Link {
  color: #fff !important;
  width: 100%;
}
section.catgeoriesBlock .blockSection .large {
  width: 60%;
}
section.catgeoriesBlock .blockSection .small {
  width: 40%;
}
section.catgeoriesBlock .blockSection .large img, section.catgeoriesBlock .blockSection .small img {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  object-fit: cover;
}
button.button-wish, .btn2{
  background-color: transparent !important;
  border: 1px solid #eb2675 !important;
  color: #eb2675 !important;
  border-radius: 0 !important;
  padding: 15px 20px;
  font-size: 14px;
  line-height: normal !important;
  font-weight: 600 !important;
  width: max-content;
  font-weight: 600 !important;
  text-transform: uppercase;
}
button.button-wish:hover, .btn2:hover{
  background-color: #eb2675 !important;
  border: 1px solid #eb2675 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

button.btn1 {
  background-color: #eb2675 !important;
  border: 1px solid #eb2675 !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding: 15px 20px;
  font-size: 14px;
  line-height: normal !important;
  font-weight: 600 !important;
  width: max-content;
}
button.btn1.small {
  font-size: 12px;
  padding: 6px 8px;
  background: transparent !important;
  color: #eb2675 !important;
}
button.btn1:hover {
  background-color: transparent !important;
  color: #eb2675 !important;
}
section.categoriesLinks {
  padding: 60px 0 0 0;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
}
section.categoriesLinks a {
  font-size: 24px;
  color: #000;
  border-bottom: 1px solid #000;
  text-decoration: none !important;
  -webkit-transition: all .3s ease-in-out;
}
section.categoriesLinks a.active {
  color: #EB2675;
  border-bottom: 1px solid #EB2675;
}
section.categoriesLinks a:hover{
  border-bottom: 1px solid #EB2675;
  color: #EB2675;
  -webkit-transition: all .3s ease-in-out;
}
.selectSorting {
  background-color: #fff;
  border: 1px solid #000;
  padding: 5px 10px;
  margin: 5px;
  font-size: 0.8rem;
}
.viewStyle button {
  background-color: #fff;
  border: 1px solid #000;
  padding: 5px 10px;
  margin: 5px;
  font-size: 1.2rem;
  transition: 0.5s;
}
.viewStyle button:hover {
  background-color: #eb2675;
  border: 1px solid #eb2675;
  color: #fff;
}

.product_list_view {
  display: flex;
  flex-direction: row;
}

.productLink {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 320px;
  width: 100%;
}

.productLink img {
  height: 100%;
}

.productCarousel {
  position: absolute;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  top: 0;
  height: 100%;
}
.product:hover figure .product-wrap .productCarousel {
  transition: linear 0.3s;
  visibility: visible;
  opacity: 1;
}

.productCarouselItem .slideImage {
  background-size: cover;
  background-repeat: no-repeat;
}

.productCarouselItem {
  position: absolute;
  width: 100%;
  border: none;
  top: 0;
  left: 100%;
  height: 100%;
}

.productCarouselItem.active {
  left: 0;
  transition: all 0.3s ease-out;
}

.productCarouselItem div {
  height: 100%;
}

.productContainer {
  position: relative;
  transition: 0.5s ease;
  height: 320px;
}
.product:hover .productContainer {
  box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.07);
}

.addTowishList {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
  padding: 15px 20px 10px 20px;
  transform: scaleY(0);
  transform-origin: bottom; /* Added to set the transform origin to the bottom */
  transition: transform 0.4s ease; /* Changed transition property to target transform */
}

.product:hover .addTowishList {
  transform: scaleY(1);
}

.addTowishList p {
  margin: 1rem 0 0 0;
  color: #333;
}

.addTowishList button {
  border: 1px solid #eb2675;
  background-color: #fff;
  text-align: center;
  width: 100%;
  display: block;
  margin: auto;
  padding: 5px 12px;
  font-size: 0.9rem;
  font-weight: bold;
}


.product h5 {
  font-size: 1rem;
  margin-top: 0.8rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 16px;
}
.stars {
  color: #eb2675;
}
.price a:first-child {
  text-decoration: line-through;
  font-size: 1.2rem;
}
.price a:nth-child(2) {
  text-decoration: none;
  color: #eb2675 !important;
  font-size: 1.2rem;
  margin-left: 10px;
}

.product img {
  width: 100%;
  vertical-align: middle;
  object-fit: cover;
  width: 100% !important;
}
.reverseSection {
  flex-direction: row-reverse;
}
.size {
  height:50%;
  width:100%;
  /*background-color:#000;*/
  
}

.relative {
  position: relative;
}
.size-table {
  position: absolute;
  bottom: 1px;
  width: 100%;
}
.sizeHover .wishlistAndSizeSection {
  width: 100% !important;
  display: flex;
  margin: 0;
  flex-direction: column;
  gap: 10px;
}
.sizeHover a.whislistLinking {
  width: 100% !important;
  display: block;
  margin: 0;
  position: relative;
  padding: 5px 12px;
}
.sizeHover a.whislistLinking::before {
  content: "\e642";
  font-family: 'Pe-icon-7-stroke';
  font-weight: 900;
  line-height: normal;
  position: relative;
  padding: 0 6px;
  transition: linear .0s;
  top: 1px;
}
.sizeHover .wishlistAndSizeSection p.productSize {
  color: #000;
  text-align: left;
  font-weight: 400;
}
@media(max-width: 1199px) {
  .specificCollection h2,.heading {
    font-size: 26px !important;  
}
section.fiveProdcutList h2, section.fashion-news h2, section.testionial h2, section.specific-collection h2 {
  font-size: 30px !important;
}
.price a{
 font-size: 15px !important;
}
.blockSection{
  gap: 15px !important;
}

}
@media (max-width: 767px) {
  .specificCollection h2 {
    font-size: 26px !important;  
}
section.fiveProdcutList h2, section.fashion-news h2, section.testionial h2, section.specific-collection h2 {
  font-size: 26px !important;
}
.heading{
  font-size: 26px !important;
}
.productName{
  padding: 10px 2px 0px;
}
.product {
  margin-bottom: 10px;
}
 .homepageContainer {
  gap: 50px !important;
  padding: 0px 50px !important;
  max-width: unset !important;
  margin-bottom: 50px;
}  
section.catgeoriesBlock .blockSection {
  flex-direction: column;
}
section.catgeoriesBlock .blockSection .large img, section.catgeoriesBlock .blockSection .small img {
  width: -webkit-fill-available;
  object-fit: cover;
}
section.catgeoriesBlock .blockSection .large, section.catgeoriesBlock .blockSection .small {
  height: 300px;
  overflow: hidden;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}    
section.catgeoriesBlock .blockSection .categoryDetailsSection {
  padding: 10px;
  left: 10px;
  bottom: 10px;
  gap: 15px;
  right: 10px;
}
 .content p {
  font-size: 16px !important;
}
section.categoriesLinks {
  padding: 30px 0 0 0;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.specificCollectionImage {
  width: 100%;
}
 section.catgeoriesBlock .blockSection {
        flex-direction: column;
    }
    section.catgeoriesBlock .blockSection .large img, section.catgeoriesBlock .blockSection .small img {
        width: -webkit-fill-available;
        object-fit: cover;
    }
    section.catgeoriesBlock .blockSection .large, section.catgeoriesBlock .blockSection .small {
        height: 300px;
        overflow: hidden;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }    
    section.catgeoriesBlock .blockSection .categoryDetailsSection {
        padding: 10px;
        left: 10px;
        bottom: 10px;
        gap: 15px;
        right: 10px;
    }
    section.fiveProdcutList .productlist .productlistPoints {
      flex-basis: 48%;
  }
  section.fiveProdcutList .productlist .productlistPoints .productlistpointsImage {
      height: 250px;
  }
}
@media (max-width: 1365px) {
  .homepageContainer{
      width: -webkit-fill-available;        
      padding-left: 50px;
      padding-right: 50px;
      max-width: unset !important;
  }
}
@media (max-width: 575px){
  .productLink,.productContainer{
    height: 500px;
  }
}
@media (max-width: 400px){
  .productLink,.productContainer{
    height: 400px;
  }
}
@media (max-width: 550px){
  .specificCollection h2 {
    font-size: 22px !important;
}
.btn1{
  padding: 10px !important;
  font-size: 12px !important;
}
.homepageContainer {
  padding-left: 20px !important;
  padding-right: 20px !important;
  max-width: unset !important;
}
section.fiveProdcutList .productlist .productListPoints {
  flex-basis: 48%;
}
section.fiveProdcutList .productlist .productListPoints .productlistpoints-image {
  height: 250px;
}
section.fiveProdcutList h2, section.fashion-news h2, section.testionial h2, section.specific-collection h2 {
  font-size: 22px !important;
}
}
@media (max-width: 600px){
  .product h5 {
    white-space: wrap ;
   }
}
@media (max-width: 1673px) {
  .priceContainer .inputPriceFilter button {
      width: 100%;
      margin: 6px 0 0;
  }
  .homePageSlider {
      height: 500px;
  }
  section.catgeoriesBlock .blockSection .large, section.catgeoriesBlock .blockSection .small {
      height: auto;
  }
}
section.testionial {
  text-align: center;
}
section.fiveProdcutList .productlist {
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}
section.fiveProdcutList .productlist .productListPoints .productlistContent p.title, section.fiveProdcutList .productlist .productListPoints .productlistContent .realprice-actualprice p {
  font-size: 16px;
}


@media (max-width: 991px){
  section.categoriesLinks a {
    font-size: 18px;
}
.product{
  padding: 0px 20px 30px;
}

.homepageContainer {
  gap: 80px;
}
section.specificCollection {
  gap: 30px;
  flex-direction: column;
}
.content p {
  font-size: 20px;
  padding-right: 0px;
}
section.fiveProdcutList {
  gap: 0px;
  align-items: center;
}
.renderProductsDiv{
  margin-top: 30px;
}
section.fiveProdcutList .btn2, section.fashioNews .btn2 {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
}
section.specificCollection .content {
  gap: 20px;
}
}
@media (max-width: 1440px) {
  section.fiveProdcutList{
      width: 100%;
  }
}