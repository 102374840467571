.cartWrapper {
  min-height: calc(100vh - 448px);
}

.container {
  width: 90%;
  margin: auto;
  padding: 30px 30px 0px;
  max-width: 1170px;
  color: #333333;
}

@media(max-width: 1024px) {
  .container {
    padding: 30px 0px 0px;
  }
}

@media(max-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media(max-width: 768px) {
  .container {
    max-width: 750px;
  }
}