.productDescriptionWrapper{
    padding: 25px 0px 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    color: #333;
    font-size: 24px !important;
}
.description {
    font-size: 14px !important;
}
@media (max-width: 767px) {
    .productDescriptionWrapper{
        padding: 0px;
    }
    .description {
        padding: 0px 18px 20px;
    }
}