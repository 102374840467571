.searchModalWrapper{
    width: 100%;
    gap: 10px;
    display: flex;
    position: relative;
    align-items: center;
}
.searchModalWrapper input {
    width: 100%;
    background-color: #f4f5f6;
    padding: 10px;
    outline: none;
    border: none;
}
.searchInputWrapper{
    background-color: #f4f5f6; 
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    padding: 0px 10px;
}
.iconBtn svg {
    width: 20px;
    height: 20px;
    stroke: #ccc;
}
.searchBtn {
    background-color: transparent ;
    border: 1px solid #eb2675 ;
    color: #eb2675 ;
    border-radius: 0 ;
    padding: 12px 20px;
    font-size: 14px;
    line-height: normal ;
    font-weight: 600 ;
    width: max-content;
    text-transform: uppercase;
}
.closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    color: #fff;
    opacity: 1;
    border-radius: 50%;
    background-color: #eb2675;
    border: 1px solid #eb2675;
    color: #fff;
    cursor: pointer;
}