.modal {
  background-color: rgba(0, 0, 0, 0.85);
  color: #333;
}
.modalContent {
  border-radius: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 600px;
}
.modalHeader {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}
.closeBtn {
  width: 30px;
  height: 30px;
  font-size: 18px;
  opacity: 1;
  border-radius: 50%;
  background-color: #eb2675;
  border: 1px solid #eb2675;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  text-align: center;
  font-weight: 600;
  color: #fff;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto 0 !important;
}
.closeBtn:hover {
  opacity: 0.5;
}
.addressFormUI {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
}
.addressList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.inputLabel {
  font-size: 13px;
  color: #333;
}
.inputWrapper input {
  background-color: #f4f5f6;
  padding: 10px;
  border: 1px solid #9d9a9b;
  width: 100%;
  border-radius: 0;
  color: #7a8081;
  line-height: 1;
}
.changeBtn {
  position: absolute;
  right: -30px;
  transform: translate(-50%, -50%);
  top: 50%;
  outline: none;
  border: 1px solid #eb2675;
  color: #eb2675;
  text-transform: uppercase;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 8px;
}
.spinner {
  position: absolute;
  right: 0;
  top: 38%;
  padding: 2px;
  margin-right: 5px;
}
.modalFooter {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.secondaryBtn,
.primaryBtn {
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #eb2675;
  padding: 8px 16px 7px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  text-transform: uppercase;
}
.secondaryBtn {
  background: transparent;
  color: #eb2675;
}
.primaryBtn {
  background: #eb2675;
  color: #fff;
  margin-left: 5px;
}
@media (max-width: 767px) {
  .modalContent {
    width: 100%;
    overflow: scroll;
  }
  .modalFooter {
    display: contents;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9984px 0 0 -5px #000;
  }
  30% {
    box-shadow: 9984px 0 0 2px #000;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #000;
  }
}

.dotPulse {
  position: absolute;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  box-shadow: 9984px 0 0 -5px #000;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dotPulse::before,
.dotPulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dotPulse::before {
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.5s;
}
