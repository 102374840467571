.billWrapper table,
th,
td {
  border: none !important;
  /* border-collapse: collapse; */
  padding: 5px;
  font-family: sans-serif !important;
}
th, td {
  font-size: 12px;
}
.productName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 55px;
}
.itemName{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.downloadBtn {
  background-color: transparent;
  border: 1px solid #eb2675;
  color: #eb2675;
  border-radius: 0;
  padding: 15px 20px;
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  width: max-content;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.billModal {
  opacity: 0 !important;
  background-color: transparent !important
}

.billContainer {
  padding: 20px;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
}

.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.HeaderWrapper img {
  width: 100px;
  height: 68px;
}

.headerTitle {
  font-size: 16px;
  margin: 0
}

.addressWrapper {
  display: flex;
  justify-content: space-between;
  margin: 12px 0px;
}
.subHeader{
  color: grey;
  padding-left: 10px;
  font-size: 15px;
}
.divider{
  width: 100%;
  height: 1px;
  background-color: #000;
}
.addresspera{
  font-size: 12px;
}
.billingHeader{
  margin-bottom: 5px;
  font-size: 15px
}
.totalDiv {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 12px
}
@media (max-width: 768px) {
  .billContainer{
    padding: 12px;
  }
  .downloadBtn {
    font-size: 12px;
    padding: 10px;
  }

  .billContainer {
    font-size: 14px;
  }

  .HeaderWrapper {
    margin-bottom: 10px;
  }

  .HeaderWrapper img {
    width: 80px;
    height: 48px;
  }

  .headerTitle {
    font-size: 14px;
  }
  .footerText{
    font-size: 12px;
  }
  .productName{
    height: 56px;
  }
}


@media (max-width: 550px) {
  .billContainer{
    padding: 10px;
  }
  .billContainer{
    font-size: 10px;
  }
  .headerTitle{
    font-size: 12px;
  }
  .HeaderWrapper img {
    height: 50px;
  }
  .productName{
    height: 50px;
  }
  .footerText{
    font-size: 10px;
  }
  .addresspera,.totalDiv{
    font-size: 8px;
  }
  .billingHeader,.subHeader{
    font-size: 10px
  }
  th, td {
    font-size: 8px;
  }
}

@media (max-width: 490px) {
  .HeaderWrapper img {
    height: 45px;
  }
  .productName{
    height: 43px;
  }
}
@media (max-width: 450px) {
  .billContainer,.footerText{
    font-size: 8px;
  }
  .headerTitle{
    font-size: 10px;
  }
  .productName{
    height: 37px;
  }
}