.tab-pane{
    padding: 25px 0px 0px 30px;
}
.specifications-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
}
.specification-heading {
    color: #333;
    font-size: 24px !important;
    padding-bottom: 20px;
}
.specifications-list .points {
    position: relative;
    border-bottom: 1px solid #EAEAEC;
    padding-bottom: 10px;
    flex-basis: 22%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.specifications-list .points p.sub-heading {
    margin: 0;
    color: #7E818C;
    font-size: 13px;
}
.specifications-list .points p {
    font-weight: 500;
    font-size: 16px;
}
@media (max-width: 767px) {
    .tab-pane{
        padding: 0px 18px 20px;
    }
    .specification-heading{
        font-size: 18px !important;
        padding: 0
    }
    .specifications-list{
        flex-direction: column;
        gap: 15px;
    }
}